import React, {
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  useCustomLayout, useDeviceLayout
} from '../../hooks'

import {
  CustomIcon,
  CustomLoading
} from '../../components'

import {
  setScheduling
} from '../../../redux/actions/main'

import {
  Wrapper,
  ContentWrapper,
  LoadingWrapper,
  SchedulingDialogContent,
  Subtitle,
  SchedulingContent,
  DateWrapper,
  WeekContent,
  DateContent,
  SchedulingButton,
  MobileHeader,
  H1,
  BackButton,
  Title,
  HeaderWrapper,
  CloseButton,
} from './styles'

import history from '../../../history'

import { selectMonth } from '../../../domain/helpers/SelectedMonth'
import { Grid } from '@material-ui/core'

export const Context = React.createContext({})

export function SchedulingPage(props) {
  const {
    loading,
    device,
    searaAvailableDates,
    userScheduling,

    setScheduling,

    handleCloseDialogSecheduling,
    URLParameters
  } = props

  const [selectedItem, setSelectedItem] = useState('')

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  useEffect(() => {
    if (userScheduling) {
      setSelectedItem(userScheduling)
    }
  }, [
    userScheduling
  ])

  useCustomLayout({
    device,
    backgroundColor: '#fff'
  })

  function handleScheduling() {
    if (!selectedItem) {
      return
    }

    setScheduling(selectedItem)

    if (handleCloseDialogSecheduling) {
      handleCloseDialogSecheduling()
      return
    }
    
    history.push(`/${URLParameters}`)
  }

  return <Wrapper>
    {isMobile ? <MobileHeader>
      <H1>Agendamento de entrega</H1>
      <BackButton className='back-button' onClick={() => {
        if (handleCloseDialogSecheduling) {
          handleCloseDialogSecheduling()
          return
        }

        history.push(`/${URLParameters}`)
      }}>
        <CustomIcon name='AngleLeft' />
      </BackButton>
    </MobileHeader> :
      <HeaderWrapper>
        <CloseButton className='close' onClick={() => {
          if (handleCloseDialogSecheduling) {
            handleCloseDialogSecheduling()
            return
          }
          history.push(`/${URLParameters}`)
        }}>
          <CustomIcon name='Times' />
        </CloseButton>
      </HeaderWrapper>}
    <ContentWrapper id='scheduling-content' >
      {loading.includes('scheduling') &&
        <LoadingWrapper>
          <CustomLoading
            type={'spin'}
            id='default-loading'
            height={40}
            width={40}
          />
        </LoadingWrapper>}
      {
        !loading.includes('scheduling') && <SchedulingDialogContent>
          <Title>Agendamento de entrega</Title>
          <Subtitle>Escolha o dia e período que deseja receber seu pedido.<br />
            {selectedItem && selectedItem.hours ? <>
              A entrega será feita das <strong>{selectedItem.hours.time}.</strong><br />
            </> : <></>}
            Fique atento à chegada da sua Ceia!</Subtitle>
          <Title>Data</Title>
          <SchedulingContent>
            <Grid container spacing={1}>
              {searaAvailableDates && searaAvailableDates.map((item, index) => {
                const key = `scheduling-list-item-${index}`

                const {
                  date,
                  day,
                  schedules
                } = item

                const splittedDate = date.split("/")
                const month = selectMonth(parseInt(splittedDate[1]))

                const objetSelectScheduling = {
                  week: day,
                  date: splittedDate[0],
                  month: month,
                  hours: schedules[0]
                }

                return (
                  <>
                    <Grid item xs={4} md={3}>
                      <DateWrapper id={key} onClick={() => setSelectedItem(objetSelectScheduling)} className={`${selectedItem && (selectedItem.date === splittedDate[0]) && (selectedItem.week === day) ? 'active' : ''}`}>
                        <WeekContent>{day}</WeekContent>
                        <DateContent>{date}</DateContent>
                      </DateWrapper>
                    </Grid>
                  </>
                )
              })}
            </Grid>
          </SchedulingContent>
          <SchedulingButton className={selectedItem && selectedItem.date ? '' : 'disabled'} onClick={() => {
            handleScheduling()
          }}>Agendar</SchedulingButton>

        </SchedulingDialogContent>
      }
    </ContentWrapper >
  </Wrapper >
}

SchedulingPage.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]))
}

const mapStateToProps = (state) => {
  return {
    device: (state.main.params && state.main.params.device) || '',
    loading: state.loading.loading || [],
    searaAvailableDates: (state.store.store && state.store.store.searaAvailableDates) || {},
    userScheduling: state.main.scheduling,
    products: (state.main.cart && state.main.cart.products) || [],
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  setScheduling
})

export const Scheduling = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'scheduling' })
)(SchedulingPage))
