export default ({
  paymentRepository,
  maps,
  auth
}) => {
  return async (payload) => {
    const {
      onSuccess,
      onError,
      data
    } = payload

    try {
      const fetchedData = await paymentRepository.fetchPixPaymentData({
        data,
        maps,
        auth: auth()
      })

      onSuccess({data: fetchedData})
    } catch(e) {
      console.log(e)

      onError()
    }
  }
}
