import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { THEME } from '../../../config'

import {
	CustomIcon,
	Div,
	DefaultParagraph as Paragraph,
	Span,
	Button,
	DefaultImage as Image
} from '../../components'

import { ReactComponent as IconCalendar } from '../../assets/icon_calendar.svg'

import {
	Wrapper
} from './styles'
import moment from 'moment'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		//height: 10,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: `${THEME.main.general.primaryColor}`,
	},
}))(LinearProgress);

const useStyles = makeStyles({
	root: {
		width: '100%',
	},
})

const getStatusLabel = (status = '') => {
	return status
}

const Steps = (props) => {
	const {
		total,
		current
	} = props

	return total ? <Div className='steps'>
		{Array.from({ length: total }, (v, k) => k).map((mappedItem, index) => {
			const key = `steps-item-${index}-${new Date().getTime()}`

			return <Div key={key} className={`${index + 1 <= current ? 'active' : ''}`}></Div>
		})}
	</Div> : null
}

export function Order(data) {
	const {
		id,
		storeLogo,
		storeName,
		time,
		status,
		modalityId,
		stepsTotal,
		currentStep,
		handleOrder,
		schedule,
		scheduling,
		limitTimePix,
		createDate,
		receipt
	} = data

	const modality = modalityId === 2 ? 'Retirada' : 'Delivery'

	const statusLabel = getStatusLabel(status)
	const classes = useStyles();
	const [progress, setProgress] = useState(0)

	useLayoutEffect(() => {
		const actualHours = getActualHours()
		const hourResult = calculateDiff({ actualHours: actualHours, createDate: createDate })
		let dividerHours = hourResult && hourResult.split(":")
		const minutesHourResult = ((Number(dividerHours[0]) * 60) + Number(dividerHours[1]))
		const secontHoursResult = Math.floor(minutesHourResult * 60)
		if (limitTimePix > secontHoursResult) {
			const sub = limitTimePix - secontHoursResult
			const porcentage = (sub * 100) / limitTimePix
			const finishPorcentage = porcentage.toString()
			setProgress(100 - finishPorcentage.substring(0, 2))
		} else {
			setProgress(100)
		}
	}, [
		calculateDiff,
		createDate,
		limitTimePix
	]);

	function getActualHours() {
		let actualDate = new Date();
		let hour = actualDate.getHours()
		let minute = actualDate.getMinutes()
		return `${hour}:${minute}`
	}

	function hmToMins(str) {
		const [hh, mm] = str && str.split(':').map(nr => Number(nr) || 0) || "";
		return hh * 60 + mm;
	}

	function calculateDiff({ actualHours, createDate }) {
		const segent = hmToMins(createDate);
		const segsai = hmToMins(actualHours);
		const diff = segsai - segent;
		if (isNaN(diff)) return;
		const hhmm = [
			Math.floor(diff / 60),
			Math.round(diff % 60)
		].map(nr => `00${nr}`.slice(-2)).join(':');

		return hhmm;
	}

	return <Wrapper className={`${status === 'PEDIDO PRONTO' ? 'ready' : ''}`} id={`home-order-tracking-items-item-${id}`}>
		<Div className='first'>
			<Image src={storeLogo} alt={storeName} />
		</Div>

		<Div className='second'>
			<Paragraph>{(scheduling || limitTimePix) ? `#${id}` : modality} | <Span>{limitTimePix ? "Aguardando Pix" : scheduling ? 'Pedido Confirmado' : statusLabel}</Span></Paragraph>
			<Paragraph>{scheduling ? <>
				<IconCalendar />{schedule} - {storeName} </> : <>
				{time || moment(createDate).format("HH:mm")} - {storeName}</>}
			</Paragraph>
			{limitTimePix ? <div className={classes.root}>
				<BorderLinearProgress variant="determinate" value={progress} />
			</div>
				: <Steps total={(scheduling || limitTimePix) ? 1 : stepsTotal} current={(scheduling || limitTimePix) ? 1 : currentStep} />}
		</Div>

		<Div className='third'>
			<Button onClick={() => {
				handleOrder({
					id,
					receipt
				})
			}}>
				<CustomIcon name='AngleRight' />
			</Button>
		</Div>
	</Wrapper>
}

Steps.propTypes = {
	total: PropTypes.number,
	current: PropTypes.number
}
