import styled from "styled-components";

export const Wrapper = styled.div`
	flex: 1;
	box-sizing: border-box;
	width: 100%;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	#cart-content {
		footer {
			padding: 0;
			display: none;
		}
	}

	#cart-list {
		padding: 0;
		min-height: 19rem;
	}

	@media (min-width: 50rem) {
		#cart-content {
			footer {
				display: flex;
			}
		}
	}
`;

export const Main = styled.div`
	grid-area: main;
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
	justify-self: center;
	margin: 0;

	@media (min-width: 50rem) {
		max-width: 65rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: "details summary";
		margin: 6rem 0 0;
		padding-top: 1rem;
	}
`;
