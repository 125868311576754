import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;

	> button {
		flex: none;
		max-height: 3rem;
		min-height: 3rem;
		min-width: 100%;
		border-radius: 0.5rem;
		cursor: pointer;
	}

	> img {
		margin-bottom: 1rem;
		min-width: 50%;
		max-width: 50%;
		min-height: auto;
		max-height: unset;
	}

	@media (min-width: 60rem) {
		padding: 0 2rem 2rem 0;
		border-right: 0.15rem solid
			${(props) =>
				(props.theme &&
					props.theme.main &&
					props.theme.main.general &&
					props.theme.main.general.grey00) ||
				"#CCC"};
	}

	@media (max-width: 60rem) {
		margin-top: 6rem;
		padding: 1rem 2rem;
		border-bottom: 0.15rem solid
			${(props) =>
				(props.theme &&
					props.theme.main &&
					props.theme.main.general &&
					props.theme.main.general.grey00) ||
				"#CCC"};
	}
`;

export const H3 = styled.h3`
	display: flex;
	flex: none;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	min-width: 100%;
	line-height: 1rem;
	margin: 0;
	margin-bottom: 1rem;
	text-transform: uppercase;
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textHighlightColor) ||
		"#000"};
`;

export const Paragraph = styled.p`
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textPrimaryColor) ||
		"#000"};
	display: flex;
	text-align: center;
	flex: none;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-width: 100%;
	margin: 0;
	padding: 0;
	margin-bottom: 1rem;
	font-size: 1rem;
`;

export const NoBorderButton = styled.button`
	background: none;
	border: 0 none;
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.primaryColor) ||
		"#000"};
	min-width: 100%;
	display: flex;
	flex: none;
	align-items: center;
	justify-content: center;
	font-size: 0.9rem;
	font-weight: bold;
	margin-bottom: 1rem;

	> span {
		border: ${(props) =>
				(props.theme &&
					props.theme.main &&
					props.theme.main.general &&
					props.theme.main.general.primaryColor) ||
				"#000"}
			0.1rem solid;
		border-radius: 50%;
		padding: 0;
		margin-left: 0.2rem;
		font-size: 0.8rem;
		min-width: 1rem;
		max-width: 1rem;
		min-height: 1rem;
		max-height: 1rem;
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
	}
`;

export const Image = styled.img``;
