import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  height: calc(100% - 4.5rem);
  min-height: calc(100% - 4.5rem);

  * {
    box-sizing: border-box;
  }

	> p {
		margin: 0;
		margin-bottom: 1.25rem;

		&:last-child {
			margin: 0;
		}
	}

  @media (min-width: 60rem) {
    height: calc(100% - 8.5rem);
    min-height: calc(100% - 8.5rem);
  }
`

export const ContentWrapper = styled.form`
  min-width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  align-content: flex-start;
  padding: 0;
  gap: 1rem;
  flex-direction: row;
  min-height: 100%;

  &.motion {
    opacity: 1;
  }

  > div {
    &.actions {
      position: absolute;
      left: 1rem;
      bottom: 1rem;
    }
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 1.25rem 0;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
  text-align: center;
  border-bottom: #d2d2d2 .063rem solid;
`

export const Button = styled.button`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff'};
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  border-radius: .938rem;
  font-size: .875rem;
  font-weight: bold;
  flex: 1;
  max-height: 3.75rem;
  min-height: 3.75rem;
  line-height: 3.75rem;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  box-sizing: border-box;
  min-width: calc(100% - 2rem);
  max-width: calc(100% - 2rem);

  @media (min-width: 60rem) {
    position: unset;
    bottom: unset;
    left: unset
  }
`

export const Input = styled.input`
	border: 0 none;
	border-bottom: #E1E1E1 .063rem solid;
	font-size: 1.125rem;
	text-align: center;
	height: 1.875rem;
	min-height: 1.875rem;
	max-height: 1.875rem;
	line-height: 1.875rem;
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
	margin: 0 auto;
	flex: 1;
	margin-bottom: 1.25rem;
	min-width: 50%;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`

export const FormFieldCardNumber = styled.div`
  border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  flex: 1;
  min-width: 100%;
  margin: 0;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;

  > img {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);
  }
`

export const FormFieldCardName = styled.div`
  flex: 1;
  min-width: 100%;
  margin: 0;
  box-sizing: border-box;
`

export const FormFieldCPF = styled.div`
  flex: 1;
  min-width: 100%;
  margin: 0;
  box-sizing: border-box;
`

export const FormFieldValidity = styled.div`
  flex: 1;
  min-width: calc(50% - .5rem);
  margin: 0;
  box-sizing: border-box;
`

export const FormFieldBirth = styled.div`
  flex: 1;
  min-width: 50%;
  margin: 0 1rem 1rem 0;
  box-sizing: border-box;
`

export const FormFieldCVV = styled.div`
  flex: 1;
  margin: 0;
  min-width: calc(50% - .5rem);
  box-sizing: border-box;
`

export const FormFieldCPFNote = styled.div`
  flex: 1;
  width: 100%;
  margin: 0 0 1rem 0;
`

export const FormActionContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  min-width: calc(100% - 2rem);
  max-width: calc(100% - 2rem);
  min-height: 3.5rem;
  max-height: 3.5rem;

  > button {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    line-height: 3.5rem;
  }

  @media (min-width: 60rem) {
    position: relative;
    min-width: 12rem;
    max-width: 12rem;
    margin-right: 0;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    bottom: unset;
    left: unset;
  }
`
export const H4 = styled.h4`
  margin: 20px 0;
  padding: 0;
  font-size: 1.125rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`
export const CloseButton = styled.button`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
`
export const HeaderWrapper = styled.header`
  width: 100%;

  > button {
    &.close {
      background: none;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
      font-size: 1.25rem;
      font-weight: 100;
    }
  }

  @media (min-width: 50rem) {
    display: none;
  }
`
