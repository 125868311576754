import React from 'react'
import PropTypes from 'prop-types'

import { Cart } from '../../cart'

import {
	Wrapper,
	OrderSummaryStore,
	StoreContainer,
	StoreLabel,
	StoreLogo,
	StoreName,
	H3
} from './styles'

export function Summary(props) {
	const {
		storeName,
		pathName,
		storeImg,
		section,
		paymentType,
		above,
		setAbove
	} = props

	return (
		<Wrapper>
			{paymentType !== 19 && <H3>Confira os detalhes do seu pedido!</H3>}

			<OrderSummaryStore>
				<StoreContainer>
					<StoreLabel>Seu pedido em:</StoreLabel>
					<StoreName>{storeName}</StoreName>
				</StoreContainer>
				<StoreLogo src={storeImg} />
			</OrderSummaryStore>

			<Cart
				section={`${section ? `${section}-summary-cart` : ``}`}
				mode='simple'
				pathName={pathName}
				paymentType={paymentType}
				above={above}
				setAbove={setAbove}
			/>
		</Wrapper>
	)
}

Summary.propTypes = {
	storeName: PropTypes.string,
	pathName: PropTypes.string,
	storeImg: PropTypes.string,
	paymentType: PropTypes.number,
	section: PropTypes.string
}
