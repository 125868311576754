import PropTypes from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";

import { SPLASH_HOME } from "../config";

import {
	Addresses,
	Cart,
	ContactUs,
	Coupons,
	MyCards,
	NewAddress,
	NewCard,
	NoStore,
	OrderConfirmation,
	PlacedOrders,
	Receipt,
	SignIn,
	SignUp,
	Store,
	StoreInfo,
	StoresList,
	Welcome,
} from "./";

import { Payment } from "./pages";

function Main(props) {
	return (
		<Switch>
			<Route
				path="/store/:productURL"
				component={(routeProps) => <Store {...props} {...routeProps} />}
			/>
			<Route
				path="/store"
				component={(routeProps) => <Store {...props} {...routeProps} />}
			/>
			<Route
				path="/payment"
				component={(routeProps) => <Payment {...props} {...routeProps} />}
			/>
			<Route
				path="/contact-us"
				component={(routeProps) => <ContactUs {...props} {...routeProps} />}
			/>
			<Route
				path="/cart"
				component={(routeProps) => <Cart {...props} {...routeProps} />}
			/>
			<Route
				path="/stores-list"
				component={(routeProps) => <StoresList {...props} {...routeProps} />}
			/>
			<Route
				path="/addresses"
				component={(routeProps) => <Addresses {...props} {...routeProps} />}
			/>
			<Route
				path="/new-address"
				component={(routeProps) => <NewAddress {...props} {...routeProps} />}
			/>
			<Route
				path="/receipt"
				component={(routeProps) => <Receipt {...props} {...routeProps} />}
			/>
			<Route
				path="/cards"
				component={(routeProps) => (
					<MyCards isPayment={true} {...props} {...routeProps} />
				)}
			/>
			<Route
				path="/my-cards"
				component={(routeProps) => <MyCards {...props} {...routeProps} />}
			/>
			<Route
				path="/new-card"
				component={(routeProps) => <NewCard {...props} {...routeProps} />}
			/>
			<Route
				path="/store-info"
				component={(routeProps) => <StoreInfo {...props} {...routeProps} />}
			/>
			<Route
				path="/no-store"
				component={(routeProps) => <NoStore {...props} {...routeProps} />}
			/>
			<Route
				path="/login"
				component={(routeProps) => <SignIn {...props} {...routeProps} />}
			/>
			<Route
				path="/sign-up"
				component={(routeProps) => <SignUp {...props} {...routeProps} />}
			/>
			<Route
				path="/placed-orders/:orderId"
				component={(routeProps) => <PlacedOrders {...props} {...routeProps} />}
			/>
			<Route
				path="/placed-orders"
				component={(routeProps) => <PlacedOrders {...props} {...routeProps} />}
			/>
			<Route
				path="/order-confirmation"
				component={(routeProps) => (
					<OrderConfirmation {...props} {...routeProps} />
				)}
			/>
			<Route
				path="/coupons/:couponId"
				component={(routeProps) => <Coupons {...props} {...routeProps} />}
			/>
			<Route
				path="/coupons"
				component={(routeProps) => <Coupons {...props} {...routeProps} />}
			/>
			<Route
				exact
				path="/:id?"
				component={(routeProps) =>
					SPLASH_HOME === "true" ? (
						<Welcome {...props} {...routeProps} />
					) : (
						<Store {...props} {...routeProps} />
					)
				}
			/>
		</Switch>
	);
}

export default Main;

Main.propTypes = {
	storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	theme: PropTypes.shape({}).isRequired,
};
