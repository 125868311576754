import React, {
	useContext,
	useEffect,
	useState
} from 'react'
import {
	connect,
	useDispatch,
	useStore
} from 'react-redux'
import { Dialog, Slide } from '@material-ui/core'
import PropTypes from 'prop-types'

import { ADDRESS_REQUEST, ALTERNATIVE_STYLE_MULTI_STORE, MULTI_STORE } from '../../config'

import history from '../../history'

import {
	setModality,
	setOpenCart,
	setShowSignUp,
	fetchStoreFees,
	handleGTM,
	addProduct,
	removeProduct,
	setCart,
	setURLParameters
} from '../../redux/actions/main'

import {
	useLayout,
	useSidebar
} from '../hooks'

import {
	addressActions,
	store as storeActions,
	menuActions
} from '../../state'

import {
	pipe,
	GetContent,
	caculateProductsQuantity
} from '../../domain/helpers'

import {
	Full,
	Simple,
	Mobile
} from './'

import {
	Addresses,
	AddressPostalCode,
	ForgotPassword,
	SignIn,
	SignUp,
	StoresList,
	useForgotPasswordDialogStyles,
	Navigation
} from '..'

import {
	Wrapper
} from './styles'
import { setOrder } from '../../state/order/Order'
import { Geolocation } from './Geolocation'
import { useSnackbar } from 'react-simple-snackbar'
import { errorOptions, successOptions } from '../../infra/utils/styleNotification'

const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

export function MainHeaderComponent(props) {
	const store = useStore()

	const address = store.getState().address
	const cartProducts = (store.getState().main.cart && store.getState().main.cart.products) || []
	const accessToken = store.getState().user.accessToken || ''
	const userName = store.getState().user.name || ''
	const modalityId = store.getState().main.modality.id || 0
	const dispatch = useDispatch()

	const storesListRequest = (store.getState().store && store.getState().store.stores && store.getState().store.stores.request) || false

	const setStoresListRequest = React.useCallback((args) => {
		dispatch(storeActions.setStoresListRequest(args))
	}, [dispatch]);

	const {
		mode,
		theme,
		name: storeName,
		userClicked,
		cartClicked,
		storeAddress,
		storeImg,
		products,
		storePickup,
		storeDelivery,
		activeDialogSignIn,
		homeDisabled = false,
		userCpf,
		subscriptionStatus,
		setOpenCart,
		showSignUp,
		setShowSignUp,
		pathName,
		homeOnClick,
		fetchStoreFees,
		fetchStore,
		URLParameters,
		addressPostalCodeRequest,
		addressRequest,
		setAddressPostalCodeRequest,
		setAddressRequest,
		setNoAddressesList,
		orderId,
		setCart,
		setOrder,
		latitude,
		longitude,
		setGeolocation,
		cep,
		handleGTM,
		directAccess,
		urlAddress,
		setAddress,
		setCEP,
		addressByCEP,
		fetchGeolocationByAddress,
		userCallback,
		userHistory,
		addProduct,
		removeProduct,
		setSelectedProduct,
		setUrlAddress,
		setURLParameters,
		setModality
	} = props

	const cartItemsQuantity = caculateProductsQuantity(cartProducts)

	const {
		layoutModeEnum,
		handleLayoutMode
	} = useLayout()

	const {
		sidebarContentEnum,
		handleSidebarContent,
		handleSidebarOpened
	} = useSidebar()

	const context = useContext(Context)
	const { sections } = context

	const storeLogo = theme.logoImageStore

	const [visibleNavigation, setVisibleNavigation] = useState(false)
	const [visibleSignIn, setVisibleSignIn] = useState(false)
	const [signUpInitialData, setSignUpInitialData] = useState(undefined)
	const [visibleForgotPassword, setVisibleForgotPassword] = useState(false)
	const [visibleGeolocation, setVisibleGeolocation] = useState(false)
	const [loadingGeolocation, setLoadingGeolocation] = useState(false)
	const [requiredValidationPhone, setRequiredValidationPhone] = useState(false)
	const [openErrorSnackbar] = useSnackbar(errorOptions({ modal: true }))
	const [openSuccessSnackbar] = useSnackbar(successOptions({ modal: true }))
	const forgotPasswordDialogClasses = useForgotPasswordDialogStyles()

	const fullSection = sections && sections.filter(item => item.id === 'full')[0]
	const simpleSection = sections && sections.filter(item => item.id === 'simple')[0]
	const mobileSection = sections && sections.filter(item => item.id === 'mobile')[0]

	useEffect(() => {
		if (urlAddress && urlAddress.main && urlAddress.number && urlAddress.neighborhood
			&& urlAddress.cep && urlAddress.city && urlAddress.state) {
			setAddress({ ...urlAddress })
			setCEP(urlAddress.cep)

			if (address && !address.id && !accessToken && address.main && address.number && address.neighborhood && address.cep && address.city && address.state) {
				const newAddressByCep = {
					...addressByCEP,
					...address
				}

				setAddress({
					...newAddressByCep,
					verified: false
				})

				if (MULTI_STORE === 'true') {
					openSuccessSnackbar('Endereço cadastrado com sucesso!')

					const newAddressByCep = {
						...addressByCEP,
						...address
					}

					setAddress({
						...newAddressByCep,
						verified: false
					})


					fetchGeolocationByAddress([{
						name: 'geolocation-by-address',
						type: 'error',
						callback: () => { }
					}, {
						name: 'geolocation-by-address',
						type: 'success',
						callback: () => { }
					}])

					history.push(`/`)
					setUrlAddress(null)
					setStoresListRequest(true)

					return
				}

				getStoreFees([{
					name: 'store-fees',
					type: 'error',
					callback: () => {
						openErrorSnackbar('Endereço fora da área de serviço! :(', 7000)

						setAddress({})
						setAddressRequest(true)
						setAddressPostalCodeRequest(false)
					}
				}, {
					name: 'store-fees',
					type: 'success',
					callback: () => {

						const newAddressByCep = {
							...addressByCEP,
							...address
						}

						setAddress({
							...newAddressByCep,
							verified: true
						})

						openSuccessSnackbar('Endereço cadastrado com sucesso!')

						setTimeout(() => {
							if (userCallback && userCallback.name && userCallback.name === 'add-product') {
								removeProduct(userCallback.data)
								addProduct(userCallback.data)

								cartClicked()
								setSelectedProduct(null)
								history.push('/')
							}

							setAddressRequest(false)
							setNoAddressesList(false)
						}, 2000)
					}
				}])
			}
		}
	}, [
		urlAddress,
		accessToken,
		address,
		addressByCEP,
		addProduct,
		removeProduct,
		cartClicked,
		fetchGeolocationByAddress,
		//getStoreFees,
		openErrorSnackbar,
		openSuccessSnackbar,
		setAddress,
		setAddressPostalCodeRequest,
		setAddressRequest,
		setCEP,
		setNoAddressesList,
		setSelectedProduct,
		setStoresListRequest,
		setUrlAddress,
		userCallback,
		userHistory
	])

	useEffect(() => {
		function verifiedRequiredAddress() {
			if (modalityId === 4 && (!address.address || !address.address.number) && ADDRESS_REQUEST === 'true') {
				return true
			} else {
				return false
			}
		}

		const addressRequired = verifiedRequiredAddress()
		if (addressRequired) {
			setAddressRequest(true)
		} else {
			setAddressRequest(false)
		}
	}, [
		modalityId,
		setAddressRequest
	])

	const getStoreFees = React.useCallback((callback) => {
		const callbackErrorFilter = callback && callback.filter(filteredItem => filteredItem.type === 'error')
		const callbackErrorItem = callbackErrorFilter && callbackErrorFilter[0]
		const callbackError = callbackErrorItem && callbackErrorItem.callback

		const callbackSuccessFilter = callback && callback.filter(filteredItem => filteredItem.type === 'success')
		const callbackSuccessItem = callbackSuccessFilter && callbackSuccessFilter[0]
		const callbackSuccess = callbackSuccessItem && callbackSuccessItem.callback

		fetchGeolocationByAddress([{
			name: 'geolocation-by-address',
			type: 'error',
			callback: () => {
				if (callbackSuccessItem.name !== 'store-fees') {
					callbackError && callbackError()
				}
			}
		}, {
			name: 'geolocation-by-address',
			type: 'success',
			callback: async () => {
				if (callbackSuccessItem.name !== 'store-fees') {
					callbackSuccess && callbackSuccess()
				}

				await fetchStoreFees(callback)
			}
		}])
	}, [fetchGeolocationByAddress, fetchStoreFees]);
	// async function getStoreFees(callback) {
	// 	const callbackErrorFilter = callback && callback.filter(filteredItem => filteredItem.type === 'error')
	// 	const callbackErrorItem = callbackErrorFilter && callbackErrorFilter[0]
	// 	const callbackError = callbackErrorItem && callbackErrorItem.callback

	// 	const callbackSuccessFilter = callback && callback.filter(filteredItem => filteredItem.type === 'success')
	// 	const callbackSuccessItem = callbackSuccessFilter && callbackSuccessFilter[0]
	// 	const callbackSuccess = callbackSuccessItem && callbackSuccessItem.callback

	// 	await fetchGeolocationByAddress([{
	// 		name: 'geolocation-by-address',
	// 		type: 'error',
	// 		callback: () => {
	// 			if (callbackSuccessItem.name !== 'store-fees') {
	// 				callbackError && callbackError()
	// 			}
	// 		}
	// 	}, {
	// 		name: 'geolocation-by-address',
	// 		type: 'success',
	// 		callback: async () => {
	// 			if (callbackSuccessItem.name !== 'store-fees') {
	// 				callbackSuccess && callbackSuccess()
	// 			}

	// 			await fetchStoreFees(callback)
	// 		}
	// 	}])
	// }

	async function handleFetchStore() {
		await fetchStore()

		if (modalityId === 4) {
			await fetchStoreFees([{
				type: 'error',
				callback: () => { }
			}, {
				type: 'success',
				callback: () => { }
			}])
		}
	}

	async function handleModality(id) {
		setModality({ id })
	}

	function selectedModality(id) {
		handleModality(id)

		if (id === 2) {
			if (!cep && !address.address.main && MULTI_STORE === 'true') {
				setAddressPostalCodeRequest(true)
			}
		}
	}

	function handleBack() {
		handleSidebarOpened(false)
		if (pathName === '/receipt') {
			handleFetchStore()
		}

		if (pathName === '/order-confirmation' && orderId) {
			setCart({ products: null })
			setOrder(null)
		}

		if (pathName === '/order-confirmation' && URLParameters === "?viewPix=true") {
			setCart({ products: null })
			setURLParameters("")
		}

		history.push(`/${URLParameters}`)
	}

	function handleContactUs() {
		history.push(`/contact-us${URLParameters}`)
	}

	function handleCloseDialogStoresList() {
		setStoresListRequest(false)
		document.body.style.overflow = null;
	}

	function handleCloseDialogAddress() {
		setAddressRequest(false)
		setAddressPostalCodeRequest(false)
	}

	function handleViewAddress() {
		if (userName || (address.address && address.address.main)) {
			setAddressRequest(true)
		} else {
			setAddressPostalCodeRequest(true)
		}
	}

	function handleCloseDialogSignIn() {
		if (requiredValidationPhone) {
			openErrorSnackbar("O cadastro do telefone é obrigatório!")
			return
		}
		setVisibleSignIn(false)
		history.push('/')
	}

	function handleCloseDialogSignUp() {
		if (requiredValidationPhone) {
			openErrorSnackbar("O cadastro do telefone é obrigatório!")
			return
		}
		setShowSignUp(false)
	}

	function handleVisibleSignUp() {
		handleCloseDialogSignIn()
		setShowSignUp(true)
	}

	function handleVisibleForgotPassword() {
		handleCloseDialogSignIn()
		setVisibleForgotPassword(true)
	}

	function handleCloseDialogForgotPassword() {
		setVisibleForgotPassword(false)
		setVisibleSignIn(true)
	}

	function handleShowSignUpFromSignIn(data) {
		setSignUpInitialData(data)
		handleVisibleSignUp()
	}

	function handleCloseDialogGeolocation() {
		setVisibleGeolocation(false)
	}

	useEffect(() => {
		if (activeDialogSignIn) {
			setVisibleSignIn(true)
		}
	}, [
		activeDialogSignIn
	])

	if (mode === 'full') {
		return (
			<Wrapper className={`main-header`}>
				<Full
					setAddressPostalCodeRequest={setAddressPostalCodeRequest}
					setAddressRequest={setAddressRequest}
					setNoAddressesList={setNoAddressesList}
					setVisibleStoreList={setStoresListRequest}
					storeName={storeName}
					userName={userName}
					userCpf={userCpf}
					subscriptionStatus={subscriptionStatus}
					address={address}
					userClicked={userClicked}
					cartClicked={cartClicked}
					handleViewAddress={handleViewAddress}
					visibleSignIn={visibleSignIn}
					setVisibleSignIn={setVisibleSignIn}
					cartItemsQuantity={cartItemsQuantity}
					context={fullSection}
					storeLogo={storeLogo}
					handleModality={selectedModality}
					storeAddress={storeAddress}
					modalityId={modalityId}
					storeImg={storeImg}
					accessToken={accessToken}
					products={products}
					storePickup={storePickup}
					storeDelivery={storeDelivery}
					homeDisabled={homeDisabled}
					setOpenCart={setOpenCart}
					homeOnClick={homeOnClick}
					URLParameters={URLParameters}
					handleGTM={handleGTM}
					setURLParameters={setURLParameters}
				/>

				<Dialog
					aria-labelledby='stores-list-dialog'
					open={storesListRequest}
					className='stores-list'
					PaperProps={{
						style: {
							maxWidth: '22rem',
							borderRadius: '.75rem'
						}
					}}
					TransitionComponent={Transition}
					maxWidth={'xs'}
					fullWidth={false}
					fullScreen={false}
				>
					<StoresList close={handleCloseDialogStoresList} />
				</Dialog>

				<Dialog
					onClose={() => {
						setAddressRequest(false)
					}}
					className='address address-root-dialog'
					aria-labelledby='address-dialog'
					open={addressRequest}
					maxWidth={'sm'}
					TransitionComponent={Transition}
				>
					<Addresses
						pathName={pathName}
						handleCloseDialogAddress={handleCloseDialogAddress}
						cartClicked={cartClicked}
					/>
				</Dialog>

				<Dialog
					onClose={() => {
						setAddressPostalCodeRequest(false)
					}}
					className='addresses-postal-code address-root-dialog'
					aria-labelledby='addresses-postal-code-dialog'
					open={addressPostalCodeRequest}
					maxWidth={'sm'}
					TransitionComponent={Transition}
					fullScreen={false}
					fullWidth={false}
				>
					<AddressPostalCode
						handleCloseDialogAddress={handleCloseDialogAddress}
						title={accessToken ? 'Alterar local de entrega?' : ''}
					/>
				</Dialog>

				<Dialog
					onClose={() => handleCloseDialogSignIn()}
					className='login'
					disableScrollLock={true}
					aria-labelledby='login-dialog'
					open={visibleSignIn}
					fullWidth={false}
					fullScreen={false}
					PaperProps={{
						style: {
							maxWidth: '25rem',
							borderRadius: '.75rem'
						}
					}}
				>
					<SignIn
						setVisibleSignIn={setVisibleSignIn}
						setVisibleSignUp={handleVisibleSignUp}
						setVisibleForgotPassword={handleVisibleForgotPassword}
						showSignUp={handleShowSignUpFromSignIn}
						close={() => { setVisibleSignIn(false) }}
						setRequiredValidationPhone={setRequiredValidationPhone}
						theme={theme}
					></SignIn>
				</Dialog>

				<Dialog
					onClose={() => handleCloseDialogSignUp()}
					aria-labelledby='signUp-dialog'
					open={showSignUp}
					maxWidth={'xs'}
					fullWidth={false}
					fullScreen={false}
				>
					<SignUp
						requiredValidationPhone={requiredValidationPhone}
						setRequiredValidationPhone={setRequiredValidationPhone}
						setVisibleSignIn={setShowSignUp}
						initialData={signUpInitialData}
						close={() => { setShowSignUp(false) }}
					>
					</SignUp>
				</Dialog>

				<Dialog
					onClose={() => handleCloseDialogForgotPassword()}
					aria-labelledby='forgot-password-dialog'
					open={visibleForgotPassword}
					maxWidth={'xs'}
					fullWidth={false}
					fullScreen={false}
					classes={{
						scrollPaper: forgotPasswordDialogClasses.scrollPaper,
						paper: forgotPasswordDialogClasses.paper
					}}
					TransitionComponent={Transition}
				>
					<ForgotPassword backStep={() => handleCloseDialogForgotPassword()} />
				</Dialog>

				<Dialog
					onClose={() => {
						setVisibleNavigation(false)
					}}
					aria-labelledby='navigation-dialog'
					open={visibleNavigation}
					maxWidth={'xs'}
					TransitionComponent={Transition}
				>
					<Navigation
						contactUs={() => handleContactUs()}
						userName={userName}
						currentRoute='store'
						userClicked={() => {
							handleLayoutMode(layoutModeEnum.headerFullContent)
							handleSidebarContent(sidebarContentEnum.cart)
							handleSidebarOpened(true)
						}}
						setVisibleNavigation={setVisibleNavigation}
					></Navigation>
				</Dialog>

				<Dialog
					onClose={false}
					aria-labelledby='geolocation-dialog'
					open={visibleGeolocation}
					TransitionComponent={Transition}
					maxWidth={'xs'}
					fullWidth={false}
					fullScreen={false}
					PaperProps={{
						style: {
							maxWidth: '22rem',
							borderRadius: '.75rem'
						}
					}}
				>
					<Geolocation
						loadingGeolocation={loadingGeolocation}
						setVisibleGeolocation={setVisibleGeolocation}
						modalityId={modalityId}
						latitude={latitude}
						longitude={longitude}
						directAccess={directAccess}
						setGeolocation={setGeolocation}
						handleCloseDialogGeolocation={handleCloseDialogGeolocation}
						setLoadingGeolocation={setLoadingGeolocation}
						setStoresListRequest={setStoresListRequest}
					/>
				</Dialog>
			</Wrapper>
		)
	} else if (mode === 'mobile') {
		return <Wrapper>
			<Mobile
				setVisibleNavigation={setVisibleNavigation}
				setVisibleStoreList={setStoresListRequest}
				modalityId={modalityId}
				storeDelivery={storeDelivery}
				storePickup={storePickup}
				context={mobileSection}
				opened={false}
				handleModality={selectedModality}
				handleGTM={handleGTM}
				storeName={storeName}
			/>
			<Dialog
				onClose={() => {
					setVisibleNavigation(false)
				}}
				aria-labelledby='navigation-dialog'
				open={visibleNavigation}
				maxWidth={'xs'}
				TransitionComponent={Transition}
			>
				<Navigation
					contactUs={() => handleContactUs()}
					userName={userName}
					currentRoute='store'
					userClicked={() => {
						handleLayoutMode(layoutModeEnum.headerFullContent)
						handleSidebarContent(sidebarContentEnum.cart)
						handleSidebarOpened(true)
					}}
					setVisibleNavigation={setVisibleNavigation}
				></Navigation>
			</Dialog>

			<Dialog
				onClose={() => {
					setAddressPostalCodeRequest(false)
				}}
				className='addresses-postal-code address-root-dialog'
				aria-labelledby='addresses-postal-code-dialog'
				open={addressPostalCodeRequest}
				maxWidth={'sm'}
				TransitionComponent={Transition}
				fullScreen={false}
				fullWidth={false}
			>
				<AddressPostalCode
					handleCloseDialogAddress={handleCloseDialogAddress}
					title={accessToken ? 'Alterar local de entrega?' : ''}
				/>
			</Dialog>

			<Dialog
				onClose={() => {
					setAddressRequest(false)
				}}
				className={!cep ? "addresses-postal-code" : "address"}
				aria-labelledby='address-dialog address-root-dialog'
				open={addressRequest}
				maxWidth={'sm'}
				TransitionComponent={Transition}
			>
				<Addresses
					pathName={pathName}
					handleCloseDialogAddress={handleCloseDialogAddress}
					cartClicked={cartClicked}
				/>
			</Dialog>

			<Dialog
				aria-labelledby='stores-list-dialog'
				open={storesListRequest && !addressRequest}
				className={ALTERNATIVE_STYLE_MULTI_STORE === "true" ? 'stores-list' : ''}
				PaperProps={{
					style: {
						maxWidth: '22rem',
						borderRadius: '.75rem',
						bottom: ALTERNATIVE_STYLE_MULTI_STORE === "true" ? '0' : '',
					}
				}}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				fullWidth={false}
				fullScreen={false}
			>
				<StoresList close={handleCloseDialogStoresList} />
			</Dialog>

			<Dialog
				onClose={false}
				className='addresses-postal-code'
				aria-labelledby='geolocation-dialog'
				open={visibleGeolocation}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				fullWidth={false}
				fullScreen={false}
				PaperProps={{
					style: {
						maxWidth: '22rem',
						borderRadius: '.75rem'
					}
				}}
			>
				<Geolocation
					loadingGeolocation={loadingGeolocation}
					setVisibleGeolocation={setVisibleGeolocation}
					modalityId={modalityId}
					latitude={latitude}
					longitude={longitude}
					directAccess={directAccess}
					setGeolocation={setGeolocation}
					handleCloseDialogGeolocation={handleCloseDialogGeolocation}
					setLoadingGeolocation={setLoadingGeolocation}
					setStoresListRequest={setStoresListRequest}
				/>
			</Dialog>
		</Wrapper>
	}

	return <Simple
		context={simpleSection}
		storeLogo={storeLogo}
		storeName={storeName}
		handleBack={handleBack}
		setVisibleStoreList={setStoresListRequest}
		setURLParameters={setURLParameters}
	/>
}

MainHeaderComponent.propTypes = {
	mode: PropTypes.string,
	theme: PropTypes.shape({
		logoImageStore: PropTypes.string
	}),
	storeName: PropTypes.string,
	userClicked: PropTypes.func,
	cartClicked: PropTypes.func,
	isCartOpen: PropTypes.bool,
	cartItemsQuantity: PropTypes.number,
	handleModality: PropTypes.func,
	storeAddress: PropTypes.string,
	modalityId: PropTypes.number,
	storeImg: PropTypes.string,
	products: PropTypes.arrayOf(PropTypes.shape({})),
	storePickup: PropTypes.bool,
	storeDelivery: PropTypes.bool,
	activeDialogSignIn: PropTypes.bool,
	homeDisabled: PropTypes.string,
	userCpf: PropTypes.string,
	subscriptionStatus: PropTypes.number,
	setOpenCart: PropTypes.func,
	showSignUp: PropTypes.bool,
	setShowSignUp: PropTypes.func,
	pathName: PropTypes.string,
	homeOnClick: PropTypes.func,
	fetchStoreFees: PropTypes.func,
	fetchStore: PropTypes.func,
	URLParameters: PropTypes.string,
	addressPostalCodeRequest: PropTypes.bool,
	addressRequest: PropTypes.bool,
	setAddressPostalCodeRequest: PropTypes.func,
	setAddressRequest: PropTypes.func,
	setNoAddressesList: PropTypes.func,
	className: PropTypes.string,
	isSidebarOpen: PropTypes.bool,
	handleGTM: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		accessToken: state.user.accessToken,
		directAccess: state.main.directAccess,
		cep: state.address.cep || null,
		address: state.address.address && state.address.address.address || null,
		latitude: state.address.latitude || null,
		longitude: state.address.longitude || null,
		addressRequest: (state.address && state.address.request) || false,
		addressPostalCodeRequest: (state.address && state.address.postalCodeRequest) || false,
		modalityId: state.main.modality && state.main.modality.id,
		products: (state.main.cart && state.main.cart.products) || [],
		showSignUp: state.main.showSignUp || false,
		subscriptionStatus: (state.main.login && state.main.login.subscriptionStatus) || 0,
		storeAddress: state.store.store && state.store.store.address,
		storeName: state.store.store && state.store.store.name,
		storeDelivery: (state.store.store && state.store.store.delivery) || null,
		storePickup: (state.store.store && state.store.store.pickup) || null,
		URLParameters: (state.main.URLParameters) || '',
		userCpf: state.main.userCpf,
		userName: state.user.name || '',
		orderId: (state.main.order && state.main.order.id) || '',
		addressByCEP: (state.address.addressByCEP) || {},
		userCallback: (state.user.callback) || {},
		userHistory: (state.main.userHistory) || {},
	}
}

const GetConnection = connect(mapStateToProps, {
	fetchStore: storeActions.fetch,
	setAddressPostalCodeRequest: addressActions.setAddressPostalCodeRequest,
	setAddressRequest: addressActions.setAddressRequest,
	setNoAddressesList: addressActions.setNoAddressesList,
	setGeolocation: addressActions.setGeolocation,
	setAddress: addressActions.setAddress,
	setCEP: addressActions.setCEP,
	fetchAddressByCEP: addressActions.fetchAddressByCEP,
	fetchGeolocationByAddress: addressActions.fetchGeolocationByAddress,
	setSelectedProduct: menuActions.setSelectedProduct,
	setModality,
	setOpenCart,
	setShowSignUp,
	fetchStoreFees,
	setCart,
	setOrder,
	handleGTM,
	addProduct,
	removeProduct,
	setURLParameters
})

export const MainHeader = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'main-header' })
)(MainHeaderComponent))
