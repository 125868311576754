import { actionTypes } from '.'

import {
  setNotification
} from '../../redux/actions/main'

import {
	getCardBrand,
	getCardTypeByBrand,
	onlyNumbers
} from '../../infra/utils'

import {
	loading
} from '../loading'

export const setPixPaymentStatus = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PIX_PAYMENT_STATUS,
    payload: status
	})
}

export const setPixPaymentData = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PIX_PAYMENT_DATA,
    payload: status
	})
}

export const setPaymentPayback = (paymentPayback) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_PAYMENT_PAYBACK,
    payload: paymentPayback
  })
}

export const setPixRequest = (pixRequest) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_PIX_REQUEST,
    payload: pixRequest
  })
}

export const setPixType = (pixType) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_PIX_TYPE,
    payload: pixType
  })
}

export const setMundipaggData = (mundipaggData) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_MUNDIPAGG_DATA,
    payload: mundipaggData
  })
}

export const setMundipaggCard = (mundipaggCard) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_MUNDIPAGG_CARD,
    payload: mundipaggCard
  })
}

export const setNewMethodData = (newMethodData) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_PAYMENT_NEW_METHOD,
    payload: newMethodData
  })
}

export const setPaymentMethod = (paymentMethod) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_PAYMENT_METHOD,
    payload: paymentMethod
  })
}

export const setPaymentMethods = (paymentMethods) => async (dispatch) => {
	dispatch({
    type: actionTypes.SET_PAYMENT_METHODS,
    payload: paymentMethods
  })
}

export const setPaymentType = (paymentType) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_PAYMENT_TYPE,
    payload: paymentType
  })
}

export const setCard = (card) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_CARD,
    payload: card
  })
}

export const setCardName = (cardName) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_CARD_NAME,
    payload: cardName
  })
}

export const setCardNumber = (cardNumber) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_CARD_NUMBER,
    payload: cardNumber
  })
}

export const setCardDate = (cardDate) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_CARD_DATE,
    payload: cardDate
  })
}

export const setCardBirth = (cardBirth) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_CARD_BIRTH,
    payload: cardBirth
  })
}

export const setCardCPF = (cardCPF) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_CARD_CPF,
    payload: cardCPF
  })
}

export const setCardBrandId = (cardBrandId) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_CARD_BRAND_ID,
    payload: cardBrandId
  })
}

export const setCardCVV = (cardCVV) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_CARD_CVV,
    payload: cardCVV
  })
}

export const fetchPaymentMethods = (callback) => async (dispatch, getState, container) => {
	const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[1] && typeof callback[1] === 'function' ? callback[1] : () => {}

	const stateLoading = (getState().loading && getState().loading.loading) || []
	const storeId = (getState().store && (getState().store.selectedStoreId || getState().store.store.id)) || null

	try {
		if (stateLoading && stateLoading.includes('payment-methods')) {
			return
		}

		dispatch(loading.setLoading('payment-methods'))

    const data = {
			storeId
		}

    const done = () => {
      dispatch(loading.setLoading({item: 'payment-methods', delete: true}))
    }

    return container.fetchPaymentMethods({
      onSuccess: ({ paymentMethods }) => {
        if (paymentMethods) {
          dispatch(setPaymentMethods(paymentMethods))

					callbackSuccess && callbackSuccess()

          done()

          return
        }

        dispatch(setPaymentMethods(null))

        done()

        return
      },
      onError: () => {
        dispatch(setPaymentMethods(null))

				callbackError && callbackError()

        done()
      },
      onEmpty: () => {
        dispatch(setPaymentMethods(null))

				callbackError && callbackError()

        done()
      },
      dispatch,
      data,
      getState
		})
  } catch (e) {
    console.log(e)

    dispatch(setPaymentMethods(null))

		callbackError && callbackError()
  }
}

export const postNewCard = (callback) => async (dispatch, getState, container) => {
	const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[1] && typeof callback[1] === 'function' ? callback[1] : () => {}

  try {
    const card = (getState().payment && getState().payment.card) || {}

    const brandId = getCardBrand(onlyNumbers(card.number))

    if (brandId && brandId.id) {
      await dispatch(setCardBrandId(
        brandId.id
      ))
    }

    await dispatch(fetchMundipaggAccessToken([() => {

		}, () => {
			
		}]))
    await dispatch(postNewCardMundipagg([() => {

		}], () => {
			
		}))

		const mundipaggCard = (getState().payment && getState().payment.mundipaggCard) || {}
		const mundipaggData = (getState().payment && getState().payment.mundipaggData) || {}

    const cardData = {
      mundipaggCardId: mundipaggCard.data.id,
      mundipaggUserId: mundipaggData.userId,
      digits: mundipaggCard.data.last_four_digits,
      firstDigits: mundipaggCard.data.first_four_digits,
      expMonth: mundipaggCard.data.exp_month,
      expYear: mundipaggCard.data.exp_year,
      brandId: brandId && brandId.id,
      type: getCardTypeByBrand(brandId && brandId.id),
      cpf: card.CPF.replace(/\.|-/g, '')
    }

		const data = {
			cardData
		}

		const done = () => {
      dispatch(loading.setLoading({item: 'payment-new-card', delete: true}))
    }

		return container.postPaymentNewCard({
      onSuccess: ({ newCard }) => {
        if (newCard) {
					dispatch(setNewMethodData(newCard))

          callbackSuccess && callbackSuccess()

          done()

          return
        }

        done()

        return
      },
      onError: () => {
        callbackError && callbackError()

				dispatch(setNotification({
					type: 'warning',
					message: 'Não foi possível cadastrar o cartão. Tente novamente.'
				}))

        done()
      },
      onEmpty: () => {
        callbackError && callbackError()

				dispatch(setNotification({
					type: 'warning',
					message: 'Não foi possível cadastrar o cartão. Tente novamente.'
				}))

        done()
      },
      dispatch,
      data
		})
  } catch (e) {
    console.log(e)

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível cadastrar o cartão. Tente novamente.'
    }))
  }
}

export const fetchMundipaggAccessToken = (callback) => async (dispatch, getState, container) => {
	const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[1] && typeof callback[1] === 'function' ? callback[1] : () => {}

	try {
    const data = {}

		const done = () => {
      dispatch(loading.setLoading({item: 'payment-mundipagg-access-token', delete: true}))
    }

		return container.fetchMundipaggAccessToken({
      onSuccess: ({ mundiPaggData }) => {
        if (mundiPaggData) {
					dispatch(setMundipaggData(mundiPaggData))

          callbackSuccess && callbackSuccess()

          done()

          return
        }

        done()

        return
      },
      onError: () => {
        callbackError && callbackError()

				dispatch(setNotification({
					type: 'warning',
					message: 'Não foi possível obter o token da Mundipagg. Tente novamente.'
				}))

        done()
      },
      onEmpty: () => {
        callbackError && callbackError()

				dispatch(setNotification({
					type: 'warning',
					message: 'Não foi possível obter o token da Mundipagg. Tente novamente.'
				}))

        done()
      },
      dispatch,
      data
		})
  } catch (e) {
    console.log(e)

		callbackError && callbackError()

    dispatch(setNotification({
			type: 'warning',
			message: 'Não foi possível obter o token da Mundipagg. Tente novamente.'
		}))
  }
}

export const postNewCardMundipagg = (callback) => async (dispatch, getState, container) => {
	const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[1] && typeof callback[1] === 'function' ? callback[1] : () => {}

	const card = getState().main && getState().main.card
	const brandId = getCardBrand(onlyNumbers(card.number))
	const mundipaggData = (getState().payment && getState().payment.mundipagg) || {}

	try {
    const data = {
      card: {
        CVV: card.CVV,
        number: card.number.replace(/\s+/g, ''),
        cpf: card.CPF.replace(/\.|-/g, ''),
        date: card.date.replace(/\/|-/g, ''),
        brandId: brandId && brandId.id,
        name: card.name
      },
      mundipaggUserId: mundipaggData.userId,
      accessToken: mundipaggData.accessToken
    }

		const done = () => {
      dispatch(loading.setLoading({item: 'payment-mundipagg-access-token', delete: true}))
    }

		return container.fetchMundipaggAccessToken({
      onSuccess: ({ mundipaggCard }) => {
        if (mundipaggCard) {
					dispatch(setMundipaggCard(mundipaggCard))

          callbackSuccess && callbackSuccess()

          done()

          return
        }

        done()

        return
      },
      onError: () => {
        callbackError && callbackError()

				dispatch(setNotification({
					type: 'warning',
					message: 'Não foi possível obter o token da Mundipagg. Tente novamente.'
				}))

        done()
      },
      onEmpty: () => {
        callbackError && callbackError()

				dispatch(setNotification({
					type: 'warning',
					message: 'Não foi possível obter o token da Mundipagg. Tente novamente.'
				}))

        done()
      },
      dispatch,
      data
		})
  } catch (e) {
    console.log(e)

		callbackError && callbackError()

    dispatch(setNotification({
			type: 'warning',
			message: 'Não foi possível obter o token da Mundipagg. Tente novamente.'
		}))
  }
}

export const deletePaymentMethod = (callback) => async (dispatch, getState, container) => {
	const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[1] && typeof callback[1] === 'function' ? callback[1] : () => {}

	const selectedPaymentMethod = (getState().payment && getState().payment.selectedPaymentMethod) || {}

	try {
    const data = {
      selectedPaymentMethod
    }

		const done = () => {
      dispatch(loading.setLoading({item: 'payment-delete-method', delete: true}))
    }

		return container.deletePaymentMethod({
      onSuccess: ({ selectedPaymentMethod }) => {
        if (selectedPaymentMethod) {
					dispatch(setNotification({
						type: 'info',
						message: 'O cartão selecionado foi removido com sucesso.'
					}))

          callbackSuccess && callbackSuccess()

          done()

          return
        }

        done()

        return
      },
      onError: () => {
        callbackError && callbackError()

				dispatch(setNotification({
					type: 'warning',
					message: 'Não foi possível excluir o cartão selecionado. Tente novamente.'
				}))

        done()
      },
      onEmpty: () => {
        callbackError && callbackError()

				dispatch(setNotification({
					type: 'warning',
					message: 'Não foi possível excluir o cartão selecionado. Tente novamente.'
				}))

        done()
      },
      dispatch,
      data
		})
  } catch (e) {
    console.log(e)

		callbackError && callbackError()

    dispatch(setNotification({
			type: 'warning',
			message: 'Não foi possível excluir o cartão selecionado. Tente novamente.'
		}))
  }
}

export const fetchPixPaymentStatus = (callback) => (dispatch, getState, container) => {
	const callbackError = callback && callback.filter(filteredItem => filteredItem.name === 'order') && (callback.filter(filteredItem => filteredItem.name === 'order'))[0] && (callback.filter(filteredItem => filteredItem.name === 'order'))[0].callback
  const callbackSuccess = callback && callback.filter(filteredItem => filteredItem.name === 'order') && (callback.filter(filteredItem => filteredItem.name === 'order'))[1] && (callback.filter(filteredItem => filteredItem.name === 'order'))[1].callback

	const done = () => {
		dispatch(loading.setLoading({ item: 'pix-payment-status', delete: true}))
	}

	const stateLoading = (getState().loading && getState().loading.loading) || []

  try {
		if (stateLoading && stateLoading.includes('pix-payment-status')) {
			throw new Error({})
		}

		dispatch(loading.setLoading('pix-payment-status'))

    const orderId = getState().main.order && getState().main.order.id

    const data = {
      orderId
    }

    return container.fetchPixPaymentStatus({
      onSuccess: ({ status }) => {
        if (status) {
          dispatch(setPixPaymentStatus({
            ...status
          }))

					callbackSuccess && callbackSuccess()

					done()
        }
      },
      onError: () => {
        dispatch(setPixPaymentStatus(null))

				callbackError && callbackError()

        done()
      },
      onEmpty: () => {
        dispatch(setPixPaymentStatus(null))

				callbackError && callbackError()

        done()
      },
      data
		})
  } catch (e) {
    console.log(e)

    callbackError && callbackError()
  }
}

export const fetchPixPaymentData = (callback) => (dispatch, getState, container) => {
	const callbackError = callback && callback.filter(filteredItem => filteredItem.name === 'order') && (callback.filter(filteredItem => filteredItem.name === 'order'))[0] && (callback.filter(filteredItem => filteredItem.name === 'order'))[0].callback
  const callbackSuccess = callback && callback.filter(filteredItem => filteredItem.name === 'order') && (callback.filter(filteredItem => filteredItem.name === 'order'))[1] && (callback.filter(filteredItem => filteredItem.name === 'order'))[1].callback

	const done = () => {
		dispatch(loading.setLoading({ item: 'pix-payment-data', delete: true}))
	}

	const stateLoading = (getState().loading && getState().loading.loading) || []

  try {
		if (stateLoading && stateLoading.includes('pix-payment-data')) {
			throw new Error({})
		} else {
			dispatch(loading.setLoading('pix-payment-data'))

			const orderId = getState().main.order && getState().main.order.id

			const data = {
				orderId
			}

			return container.fetchPixPaymentData({
				onSuccess: ({ data }) => {
					if (data) {
						dispatch(setPixPaymentData({
							...data
						}))

						callbackSuccess && callbackSuccess()

						done()

						return
					}

					dispatch(setPixPaymentData(null))

					callbackError && callbackError()

					done()
				},
				onError: () => {
					dispatch(setPixPaymentData(null))

					callbackError && callbackError()

					done()
				},
				onEmpty: () => {
					dispatch(setPixPaymentData(null))

					callbackError && callbackError()

					done()
				},
				data
			})
		}
  } catch (e) {
    console.log(e)

    callbackError && callbackError()
  }
}
