import React from 'react'
import PropTypes from 'prop-types'

import {
  Item
} from './styles'

export function SelectedItem({ children }) {
  return <Item> { children } </Item>
}

SelectedItem.propTypes = {
	children: PropTypes.node
}
