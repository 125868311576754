import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;

	> button {
		flex: none;
		max-height: 3rem;
		min-height: 3rem;
		min-width: 100%;
		border-radius: .5rem;
		cursor: pointer;
	}

	> img {
		margin-bottom: 1rem;
		min-width: 50%;
		max-width: 50%;
		min-height: auto;
		max-height: unset;
	}

	@media (min-width: 60rem) {
		padding: 0 2rem 2rem 0;
		border-right: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#CCC'};
	}
`

export const H3 = styled.h3`
	display: flex;
	flex: none;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	min-width: 100%;
	line-height: 1rem;
	margin: 1rem 0 0 0;
	margin-bottom: 1rem;
	text-transform: uppercase;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`

export const Paragraph = styled.p`
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
	display: flex;
	text-align: center;
	flex: none;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-width: 100%;
	margin: 0;
	padding: 0;
	margin-bottom: 1rem;
	font-size: 1rem;
`

export const Erro = styled.div`
	background-color: #F7F7F7;
	border: 1px solid #CECECE;
	border-radius: 16px;
	padding: .8rem;
	text-align: center;
	width: 100%;
	color: #939598;
	display:flex;
	flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const Text = styled.div`
	margin: 0 1rem 0 0;
`