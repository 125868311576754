import React, {
	useEffect,
	useState,
	useCallback
} from 'react'
import PropTypes from 'prop-types'
import {
	connect,
	useDispatch,
	useStore
} from 'react-redux'
import {
	Dialog,
	Slide
} from '@material-ui/core'
import { Helmet } from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import {
	cart,
	coupon,
	order,
	payment,
} from '../../state'

import history from '../../history'

import {
	pipe,
	GetContent,
	calculateCartTotal
} from '../../domain/helpers'

import {
	setCart,
	setUserHistory,
	setNotification,
	setCVV,
	setScheduling,
	setOrder,
	setSelectedDeliveryDate,
	handleGTM
} from '../../redux/actions/main'

import {
	CouponsList
} from '../coupons/simple'

import {
	MainHeader
} from '../main-header'

import { DeliveryDateSelecion } from './Details/DeliveryDateSelecion'

import {
	Wrapper,
	Main,
} from './styles'

import {
	Details,
	Summary,
	Pix
} from './'
import { Info } from './Pix/Info'
import { PixLimitTime } from './PixLimitTime'
import { SummaryPix } from './SummaryPix'

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

function OrderConfirmationComponent(props) {
	const store = useStore()
	const dispatch = useDispatch()

	const selectedPaymentMethod = (store.getState().payment && store.getState().payment.selectedPaymentMethod) || {}
	const pixPaymentStatus = (store.getState().payment && store.getState().payment.pixPaymentStatus) || {}
	const pixPaymentData = (store.getState().payment && store.getState().payment.pixPaymentData) || {}
	const stateLoading = (store.getState().loading && store.getState().loading.loading) || []

	const {
		address,
		storeName,
		storeStatus,
		cpf,
		location,
		theme,
		addCoupon,
		setCoupon,
		setCartCoupon,
		coupon,
		cartCoupon,
		handleGTM,
		postOrder,
		setCart,
		setUserHistory,
		setNotification,
		modalityId,
		storeImg,
		storeAddress,
		scheduling,
		selectedDeliveryDate,
		setSelectedDeliveryDate,
		deliveryFee,
		products,
		URLParameters,
		couponField,
		orderId,
		setOrder,
	} = props

	const storeLogo = theme.logoImageStore

	const [hasCpf, setHasCpf] = useState(false)
	const [visibleCoupons, setVisibleCoupons] = useState(false)
	const [deliveryDateSelectionOpened, setDeliveryDateSelectionOpened] = useState(false)
	const [error, setError] = useState(undefined)
	const [success, setSuccess] = useState(false)
	const [above, setAbove] = useState(false)
	const [openDialogPix, setOpenDialogPix] = useState(false)
	const [cartTotal, setCartTotal] = useState(0)

	const {
		url,
		title,
		description
	} = useStoreSettings()

	const handleFetchPixPaymentData = useCallback(() => {
		if ((selectedPaymentMethod.paymentType === 19 && !pixPaymentData.qrCodeUrl && orderId) ||
			(selectedPaymentMethod.paymentType === 19 && orderId && URLParameters === "?viewPix=true")) {
			dispatch(payment.fetchPixPaymentData([{
				type: 'error',
				callback: () => { }
			}, {
				type: 'success',
				callback: () => {
				}
			}]))
		}
	}, [
		dispatch,
		selectedPaymentMethod,
		pixPaymentData,
		orderId
	])

	const handleFetchPixPaymentStatus = useCallback(() => {
		if (selectedPaymentMethod.paymentType === 19 && pixPaymentData.qrCodeUrl && orderId) {
			dispatch(payment.fetchPixPaymentStatus([{
				type: 'error',
				callback: () => {

				}
			}, {
				type: 'success',
				callback: () => {

				}
			}]))
		}

		return () => { }
	}, [
		dispatch,
		selectedPaymentMethod,
		pixPaymentData,
		orderId
	])

	useEffect(() => {
		const timeout = setTimeout(() => handleFetchPixPaymentData(), 5000)

		return () => {
			clearTimeout(timeout)
		}
	}, [handleFetchPixPaymentData])

	useEffect(() => {
		const timeout = setTimeout(() => handleFetchPixPaymentStatus(), 5000)

		return () => {
			clearTimeout(timeout)
		}
	}, [
		selectedPaymentMethod,
		pixPaymentData,
		handleFetchPixPaymentStatus
	])

	useEffect(() => {
		if (pixPaymentStatus && pixPaymentStatus.success && pixPaymentStatus.paymentStatus === 3 && orderId) {
			setTimeout(() => {
				history.push(`/receipt${URLParameters}`)
				setCart({ products: null })
			}, 2000)
		}
	}, [
		pixPaymentStatus,
		URLParameters,
		orderId,
		setCart
	])

	useEffect(() => {
		setCartTotal(calculateCartTotal(products))
	}, [
		products,
	])

	function postCoupon() {
		addCoupon([(data) => {
			setCartCoupon({
				error: true
			})
			setError(data.message)
			setCoupon('')
			setTimeout(() => {
				setError(undefined)
			}, 5500)
		}, (data) => {
			setCartCoupon({
				code: coupon,
				...data
			})
			setSuccess(true)
			setTimeout(() => {
				setSuccess(false)
			}, 5500)
		}])
	}

	function handleCoupon(data) {
		setCartCoupon({
			...data
		})

		setVisibleCoupons(false)
	}

	function handleAddCoupon() {
		postCoupon()
	}

	function handleRemoveCoupon() {
		setCartCoupon('')
		setCoupon('')
	}

	function handleViewCoupons() {
		setVisibleCoupons(true)
	}

	function handleCloseDialogCoupons() {
		setVisibleCoupons(false)
	}

	function handleHasCpf() {
		setHasCpf(!hasCpf)
	}

	async function handleFinish() {
		if (storeStatus && storeStatus !== 1) {
			setNotification({
				type: 'warning',
				message: 'Ops! A loja está fechada. Tente em outro horário!!'
			})

			return
		}

		await postOrder([{
			name: 'order',
			type: 'error',
			callback: () => {

			}
		}, {
			name: 'order',
			type: 'success',
			callback: (payload) => {

				const cart = cartTotal?.toFixed(2)
				const finishCart = parseFloat(cart)

				const checkoutDataFinish = {
					'event': `purchase`,
					'ecommerce': {
						'currency': 'BRL',
						'store': storeName,
						'value': finishCart || null,
						'shipping': deliveryFee || null,
						'affiliation': storeName || null,
						'transaction_id': payload.orderId || 0,
						'coupon': couponField || null,
						'modalidade_retirada': modalityId === 2 ? 'retirar_na_loja' : 'delivery',
						'items': products ? products.map(function (item) {
							return {
								'item_name': item.name,
								'item_id': item.id,
								'price': item.promotionalValue ? item.promotionalValue : item.value,
								'discount': null,
								'item_category': item.category.name || null,
								'quantity': item.quantity
							}
						}) : []
					}
				}

				handleGTM(checkoutDataFinish)

				setCart({
					products: null
				})

				setSelectedDeliveryDate(null)

				history.push(`/receipt${URLParameters}`)
			}
		}, {
			name: 'address',
			type: 'error',
			callback: () => {
				setUserHistory({
					next: 'cart',
					back: 'cart'
				})

				setNotification({
					type: 'warning',
					message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
				})

				history.push(`/new-address${URLParameters}`)
			}
		}, {
			name: 'address',
			type: 'success',
			callback: () => {
				console.warn('Address created')
			}
		}], {
			update: false
		})
	}

	return <Wrapper>
		<Helmet>
			<title>{`${title} - Confirmação de pedido`}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={`${url}/order-confirmation`} />
		</Helmet>

		<MainHeader
			theme={theme}
			mode='simple'
			storeName={storeName}
			storeLogo={storeLogo}
			pathName={location.pathname}
		/>

		<Main>
			{!orderId && <Details
				loading={stateLoading}
				selectedPaymentMethod={selectedPaymentMethod}
				address={address}
				coupon={cartCoupon ? cartCoupon.code : coupon}
				cartCoupon={cartCoupon}
				setCoupon={setCoupon}
				hasCpf={hasCpf}
				cpf={cpf}
				storeStatus={storeStatus}
				handleRemoveCoupon={handleRemoveCoupon}
				handleHasCpf={handleHasCpf}
				handleFinish={handleFinish}
				handleAddCoupon={handleAddCoupon}
				handleViewCoupons={handleViewCoupons}
				theme={theme}
				modalityId={modalityId}
				storeAddress={storeAddress}
				scheduling={scheduling}
				setDeliveryDateSelectionOpened={setDeliveryDateSelectionOpened}
				selectedDeliveryDate={selectedDeliveryDate}
				URLParameters={URLParameters}
				error={error}
				success={success}
				couponField={couponField}
				above={above}
			/>}

			<Summary
				section={`order-confirmation`}
				scheduling={scheduling}
				mode='simple'
				storeName={storeName}
				storeLogo={storeLogo}
				storeImg={storeImg}
				pathName={location.pathname}
				paymentType={selectedPaymentMethod.paymentType}
				setAbove={setAbove}
			/>

			{selectedPaymentMethod.paymentType === 19 && orderId && pixPaymentStatus.paymentStatus !== 4 && <SummaryPix
				section={`order-confirmation`}
				scheduling={scheduling}
				mode='simple'
				storeName={storeName}
				storeLogo={storeLogo}
				storeImg={storeImg}
				pathName={location.pathname}
				paymentType={selectedPaymentMethod.paymentType}
				setAbove={setAbove}
				products={pixPaymentData.items}
			/>}

			{selectedPaymentMethod.paymentType === 19 && orderId && pixPaymentStatus.paymentStatus !== 4 && <Pix
				qrCodeImage={pixPaymentData.qrCodeUrl}
				copyPasteCode={pixPaymentData.qrCode}
				limitTime={pixPaymentData.limitTime}
				setOpenDialogPix={setOpenDialogPix}
			/>}

			{pixPaymentStatus && pixPaymentStatus.success && pixPaymentStatus.paymentStatus === 4
				&& orderId && <PixLimitTime
					URLParameters={URLParameters}
					orderId={orderId}
					pathName={location.pathname}
					setCart={setCart}
					setOrder={setOrder}
				/>}

			<Dialog
				onClose={() => handleCloseDialogCoupons()}
				aria-labelledby="coupons-list-dialog"
				open={visibleCoupons}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				className="my-coupons"
				fullWidth={true}
			>
				<CouponsList
					theme={theme}
					handleCoupon={handleCoupon}
					handleCloseCoupon={setVisibleCoupons}
					modal={true}
				/>
			</Dialog>
			<Dialog
				onClose={() => setDeliveryDateSelectionOpened(false)}
				aria-labelledby='delivery-date-selection'
				open={deliveryDateSelectionOpened}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				PaperProps={{
					style: {
						width: '25rem',
						borderRadius: '.75rem'
					}
				}}
				fullWidth={false}
			>
				<DeliveryDateSelecion
					selectedDeliveryDate={selectedDeliveryDate}
					close={() => setDeliveryDateSelectionOpened(false)} />
			</Dialog>

			{
				selectedPaymentMethod.paymentType === 19 && orderId && <Dialog
					onClose={() => { setOpenDialogPix(false) }}
					className='address'
					aria-labelledby='address-dialog'
					open={selectedPaymentMethod && selectedPaymentMethod.paymentType === 19 && openDialogPix}
					maxWidth={'sm'}
					TransitionComponent={Transition}
				>
					<Info setOpenDialogPix={setOpenDialogPix} />
				</Dialog>
			}
		</Main >
	</Wrapper >
}

OrderConfirmationComponent.propTypes = {
	address: PropTypes.shape({}),
	storeName: PropTypes.string,
	storeStatus: PropTypes.number,
	cpf: PropTypes.string,
	location: PropTypes.shape({
		pathname: PropTypes.string
	}),
	theme: PropTypes.shape({
		logoImageStore: PropTypes.string
	}),
	addCoupon: PropTypes.func,
	setCoupon: PropTypes.func,
	setCartCoupon: PropTypes.func,
	coupon: PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.string,
		minValue: PropTypes.number,
		typeValueModalityDelivery: PropTypes.number
	}),
	cartCoupon: PropTypes.shape({
		code: PropTypes.string
	}),
	handleGTM: PropTypes.func,
	postOrder: PropTypes.func,
	setCart: PropTypes.func,
	setUserHistory: PropTypes.func,
	setNotification: PropTypes.func,
	modalityId: PropTypes.number,
	storeImg: PropTypes.string,
	storeAddress: PropTypes.string,
	scheduling: PropTypes.bool,
	userScheduling: PropTypes.shape({}),
	setScheduling: PropTypes.func,
	setOrder: PropTypes.func,
	deliveryFee: PropTypes.number,
	products: PropTypes.arrayOf(PropTypes.shape({})),
	URLParameters: PropTypes.string,
	couponField: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		storeName: state.store.store && state.store.store.name,
		storeStatus: state.store.store && state.store.store.status,
		coupon: state.main.coupon || '',
		couponField: state.main.couponField || '',
		cartCoupon: (state.main.cart && state.main.cart.coupon) || '',
		cpf: state.user.cpf || '',
		userCard: state.main.userCard || '',
		address: state.address.address || '',
		loading: state.loading.loading || '',
		products: (state.main.cart && state.main.cart.products) || [],
		modalityId: state.main.modality && state.main.modality.id,
		storeImg: (state.store.store && state.store.store.logo) || '',
		storeAddress: state.store.store && state.store.store.address,
		scheduling: state.store.store && state.store.store.scheduling,
		selectedDeliveryDate: state.main.selectedDeliveryDate,
		deliveryFee: state.store.store && state.store.store.deliveryFee,
		URLParameters: (state.main.URLParameters) || '',
		orderId: (state.main.order && state.main.order.id) || '',
	}
}

const GetConnection = connect(mapStateToProps, {
	addCoupon: coupon.addCoupon,
	setCoupon: coupon.setSelectedCoupon,
	postOrder: order.addOrder,
	setCartCoupon: cart.setCartCoupon,
	setCart,
	setUserHistory,
	setNotification,
	setCVV,
	setScheduling,
	setOrder,
	setSelectedDeliveryDate,
	handleGTM
})

export const OrderConfirmation = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'order-confirmation' })
)(OrderConfirmationComponent))
