import React from 'react'
import PropTypes from 'prop-types'

import {
	PaymentMethodsGroup
} from '.'

import {
	Wrapper 
} from './styles'

export function PaymentMethodsDisplay(props) {
	const {
		data,
		context,
		handleOpenModal,
		handleCard,
		handleNewItem,
		setSelectRemoveCard,
		isPayment
	} = props

	const { sections } = context

	return <Wrapper>
		{data && data.map((item, index) => {
			const sectionItem = sections[index]
			const sectionItemId = sectionItem.id
			const groupName = sections[index].data.header.title
			const itemId = sectionItemId
			const key = `payment-methods-group-item-${index}-${itemId}`

			return ((!isPayment && sectionItemId === 'online-credit-card') || (isPayment)) && <PaymentMethodsGroup
				key={key}
				name={groupName}
				data={item}
				addNewItem={sectionItemId === 'online-credit-card'}
				removeItem={sectionItemId === 'online-credit-card'}
				selectItem={(isPayment || sectionItemId === 'online-credit-card')}
				handleCard={handleCard}
				handleNewItem={handleNewItem}
				handleOpenModal={handleOpenModal}
				setSelectRemoveCard={setSelectRemoveCard}
				/>
		})}
	</Wrapper>
}

PaymentMethodsDisplay.propTypes = {
	data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
	context: PropTypes.shape({
		sections: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string,
			data: PropTypes.shape({
				header: PropTypes.shape({
					title: PropTypes.string
				})
			})
		}))
	}),
	handleNewItem: PropTypes.func,
	handleCard: PropTypes.func,
	handleOpenModal: PropTypes.func,
	setSelectRemoveCard: PropTypes.func,
	isPayment: PropTypes.bool
}
