import React from 'react'

import { ReactComponent as PixLogo } from '../../assets/pix_logo.svg'
import ErrorPix from '../../assets/icon_error.png'
import {
	Button
} from '../../components'

import {
	Wrapper,
	H3,
	Paragraph,
	Erro,
	Text,
} from './styles'
import history from '../../../history'

export function PixLimitTime({
	URLParameters,
	orderId,
	pathName,
	setCart,
	setOrder
}) {
	function handleBack() {
		if (pathName === '/order-confirmation' && orderId) {
			setCart({ products: null })
			setOrder(null)
		}

		history.push(`/${URLParameters}`)
	}
	return (
		<Wrapper>
			<Erro><Text>Codigo pix expirado! :(</Text> <img src={ErrorPix} alt="erro pix" /></Erro>

			<Paragraph>
				<PixLogo />
				<H3>Pagamento não realizado</H3>
				O pagamento via pix não foi confirmado. Que tal tentar fazer seu pedido novamente?
			</Paragraph>

			<Button onClick={() => { handleBack() }}>Voltar</Button>
		</Wrapper>
	)
}

PixLimitTime.propTypes = {}
