import React from 'react'
import PropTypes from 'prop-types'
import {
	useDispatch
} from 'react-redux'

import { maskCpf } from '../../../../domain/helpers'
import history from '../../../../history'

import { getCardBrandImageById } from '../../../../infra/utils'

import { payment } from '../../../../state'

import { ReactComponent as LocationIcon } from '../../../assets/icons/icon_checkout_address.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/icon_edit_fill.svg'

import { SelectedItem } from './SelectedItem'

import {
	Wrapper,
	SelectedItemContent,
	PaymentMethodContent,
	CPFContent,
	DeliveryContent,
	ItemLabel,
	SelectedItemAction,
	EditButton,
	BrandImage,
	SelectedItemContentText
} from './styles'
import { setNotification } from '../../../../redux/actions/main'

export function SelectedItems(props) {
	const dispatch = useDispatch()

	const {
		selectedPaymentMethod,
		address,
		cpf,
		modalityId,
		storeAddress,
		scheduling,
		above,
		// changeSelectedPaymentMethod,
		changeSelectedDeliveryDate,
		selectedDeliveryDate,
		URLParameters
	} = props

	const selectAddressDelivery = address.main || address.backup

	const displayAddress = modalityId === 4 ?
		(selectAddressDelivery && selectAddressDelivery.length > 30 ?
			(selectAddressDelivery.slice(0, -17) + '...') : selectAddressDelivery) + (address.number ? `, ` + address.number : '')
		: storeAddress.slice(0, -9) + '...'

	const fullDisplayAddress = modalityId === 4 ?
		selectAddressDelivery + (address.number ? `, ` + address.number : '') :
		storeAddress

	const getFormatedDate = () => {
		const date = new Date(selectedDeliveryDate.date)
		const week = date.toLocaleDateString('pt-BR', { weekday: 'long' }).split('-')[0]
		const month = date.toLocaleDateString('pt-BR', { month: 'long' })
		const day = date.toLocaleDateString('pt-BR', { day: 'numeric' })
		const year = date.toLocaleDateString('pt-BR', { year: 'numeric' })
		return `${week}, ${day} de ${month} de ${year}, ${selectedDeliveryDate.hour.time}`
	}

	return <Wrapper>
		{scheduling && (modalityId === 4 || modalityId === 2) && <SelectedItem>
			<SelectedItemContent>
				<ItemLabel>Data da entrega</ItemLabel>
				<DeliveryContent>
					<SelectedItemContentText onClick={() => changeSelectedDeliveryDate()} style={{
						cursor: 'pointer'
					}}>
						{selectedDeliveryDate ? getFormatedDate() : 'Selecionar data e horário'}
					</SelectedItemContentText>
				</DeliveryContent>
			</SelectedItemContent>
			<SelectedItemAction>
				<EditButton onClick={() => {
					changeSelectedDeliveryDate()
				}}>
					<EditIcon />
				</EditButton>
			</SelectedItemAction>
		</SelectedItem>}


		<SelectedItem>
			<SelectedItemContent>
				<ItemLabel>{modalityId === 4 ? 'Local de entrega' : 'Local de retirada'}</ItemLabel>
				<DeliveryContent>
					<LocationIcon />
					<span>{fullDisplayAddress && fullDisplayAddress.length > 16 ? displayAddress : fullDisplayAddress}</span>
				</DeliveryContent>
			</SelectedItemContent>
		</SelectedItem>
		{above && <SelectedItem>
			<SelectedItemContent>
				<ItemLabel>Forma de pagamento</ItemLabel>
				<PaymentMethodContent>
					<BrandImage
						src={selectedPaymentMethod.image}
						alt={`Bandeira do cartao: ${getCardBrandImageById(selectedPaymentMethod.brand)}`}
					/>
					<span>{selectedPaymentMethod.data}</span>
				</PaymentMethodContent>
			</SelectedItemContent>
			<SelectedItemAction>
				<EditButton onClick={() => {
					dispatch(payment.setPaymentMethod(null))
					dispatch(payment.setPaymentPayback(0))
					dispatch(setNotification(null))

					history.push(`/payment${URLParameters}`)
					//changeSelectedPaymentMethod()
				}}>
					<EditIcon />
				</EditButton>
			</SelectedItemAction>
		</SelectedItem>}
		<SelectedItem>
			<SelectedItemContent>
				<ItemLabel>CPF na nota</ItemLabel>
				{cpf ? <CPFContent>
					<span>{maskCpf(cpf)}</span>
				</CPFContent> : <CPFContent>
					<span>000.000.000-00</span>
				</CPFContent>}
			</SelectedItemContent>
		</SelectedItem>
	</Wrapper>
}

SelectedItems.propTypes = {
	selectedPaymentMethod: PropTypes.shape({
		brandId: PropTypes.number,
		brand: PropTypes.string,
		data: PropTypes.string
	}),
	address: PropTypes.shape({
		main: PropTypes.string,
		backup: PropTypes.string,
		number: PropTypes.number
	}),
	cpf: PropTypes.string,
	modalityId: PropTypes.number,
	storeAddress: PropTypes.string,
	scheduling: PropTypes.bool,
	setVisibleScheduling: PropTypes.func,
	userScheduling: PropTypes.shape({
		week: PropTypes.number,
		date: PropTypes.number,
		month: PropTypes.number
	}),
	URLParameters: PropTypes.string,
}
