import React, {
	useEffect, useState
} from 'react'
import {
	connect,
	useDispatch,
	useStore
} from 'react-redux'
import PropTypes from 'prop-types'

import history from '../../../history'

import {
	setUserHistory,
	setScheduling,
	setNotification
} from '../../../redux/actions/main'

import {
	payment,
	order
} from '../../../state'

import {
	pipe,
	GetContent
} from '../../../domain/helpers'

import {
	useDeviceLayout
} from '../../hooks'

import {
	Button, CustomLoading
} from '../../components'

import {
	Tabs
} from './Tabs'

import {
	Wrapper,
	H3
} from './styles'

export const Context = React.createContext({})

export function PixComponent(props) {
	const store = useStore()
	const dispatch = useDispatch()

	const { loading, setOpenDialogPix } = props
	const [pixType, setPixType] = useState(0)

	const URLParameters = (store.getState().main && store.getState().main.URLParameters) || ''

	const isMobile = useDeviceLayout({
		isMobile: true
	})

	useEffect(() => {
		return () => { }
	}, [])

	function handleType(index) {
		setPixType(index)
	}

	async function handleOk() {
		dispatch(payment.setPixRequest(false))

		await dispatch(order.addOrder([{
			name: 'order',
			type: 'error',
			callback: () => {
				dispatch(payment.setPaymentMethod(null))
				dispatch(payment.setPaymentPayback(0))
				dispatch(setNotification({
					type: 'warning',
					message: 'Ocorreu um erro no pagamento.'
				}))
				dispatch(setScheduling(null))
				console.warn("Erro")

			}
		}, {
			name: 'order',
			type: 'success',
			callback: () => {
				dispatch(setScheduling(null))
				history.push(`/order-confirmation${URLParameters}`)
				setOpenDialogPix(false)
				console.warn("Sucesso")
			}
		}, {
			name: 'address',
			type: 'error',
			callback: () => {
				dispatch(setUserHistory({
					next: 'cart',
					back: 'cart'
				}))

				dispatch(setNotification({
					type: 'warning',
					message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
				}))

				history.push(`/new-address${URLParameters}`)
				console.warn("Erro")
			}
		}, {
			name: 'address',
			type: 'success',
			callback: () => {
				console.warn('Address created')
			}
		}]))
	}

	return <Wrapper id='payment-method-pix-how-to'>
		<H3>Como pagar com PIX:</H3>

		<Tabs tab={pixType} handleType={handleType} />

		<Button onClick={() => {
			console.warn("Payment Mobile")
			handleOk()
		}}>{(loading.includes('add-order') ||
			loading.includes('analysis-order')) ?
			<CustomLoading
				color={'#fff'}
				type={'spin'}
				id='default-loading'
				height={30}
				width={30} /> :
			'Ok'}</Button>
	</Wrapper>
}

PixComponent.propTypes = {
	setPixRequest: PropTypes.func,
	setPixType: PropTypes.func,
	addOrder: PropTypes.func,
	setPaymentMethod: PropTypes.func,
	setNotification: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		loading: (state.store.store && state.loading.loading) || [],
	}
}

const GetConnection = connect(mapStateToProps, {})

export const Pix = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'payment' })
)(PixComponent))
