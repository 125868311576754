import styled from 'styled-components'

export const Wrapper = styled.div`
	z-index: 1;
	position: relative;
  max-height: 4.225rem;
	min-height: 4.225rem;

	@media (min-width: 40rem) {
		display: flex;
	}
`
