import React, {
	useEffect,
	useState,
	useCallback
} from 'react'
import PropTypes from 'prop-types'
import {
	connect,
	useStore,
	useDispatch
} from 'react-redux'
import { Dialog, Slide } from '@material-ui/core'

import history from '../../history'

import {
	pipe,
	GetContent
} from '../../domain/helpers'

import {
	CARD_BRANDS_TAG
} from '../../domain/enum/payment'

import {
	onlyNumbers,
	getCardBrand,
	getCardBrandImageById
} from '../../infra/utils'

import {
	handleGTM,
	setNotification,
} from '../../redux/actions/main'

import {
	loading,
	payment
} from '../../state'

import {
	CustomLoading,
	CustomModalNotification,
	Button
} from '../components'

import { useDeviceLayout } from '../hooks'

import {
	Notification
} from '..'

import {
	NewCard,
	PaymentMethodsDisplay,
	Pix
} from '.'

import {
	Wrapper,
	ContentWrapper,
	LoadingWrapper,
	ActionContainer
} from './styles'

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

export function PaymentMethodsComponent(props) {
	const store = useStore()
	const dispatch = useDispatch()

	const onlineMethods = (store.getState().payment && store.getState().payment.availableOnlineMethods) || []
	const onlineCards = (store.getState().payment && store.getState().payment.availableOnlineCards) || []
	const offlineMethods = (store.getState().payment && store.getState().payment.availableOfflineMethods) || []
	const loading = (store.getState().loading && store.getState().loading.loading) || []
	const selectedPaymentMethod = (store.getState().payment && store.getState().payment.selectedPaymentMethod) || {}

	const {
		context,
		postNewCard,
		isPayment,
		addTicketCard,
		setCardName,
		setCardNumber,
		setCardDate,
		setCardBirth,
		setCardBrandId,
		setCardCPF,
		setCardCVV,
		deleteCard,
		handleGTM,
		URLParameters
	} = props

	const {
		name: cardName,
		number: cardNumber,
		date: cardDate,
		CPF: cardCPF,
		CVV: cardCVV,
		birth: cardBirth
	} = selectedPaymentMethod

	const [newCard, setNewCard] = useState(false)
	const [visiblePayback, setVisiblePayback] = useState(false)
	const [paybackValue, setPaymentPaybackValue] = useState(0)
	const [cardBrandTicket, setCardBrandTicket] = useState(null)
	const [selectCardBrandId, setSelectCardBrandId] = useState(null)
	const [selectRemoveCard, setSelectRemoveCard] = useState(null)
	const [deleteModalCard, setDeleteModalCard] = useState(false)
	const [openDialogPix, setSetOpenDialogPix] = useState(false)

	const handleFetchPaymentMethodsCallback = useCallback(() => {
		dispatch(payment.fetchPaymentMethods())
	}, [
		dispatch
	])

	const isMobile = useDeviceLayout({
		isMobile: true
	})

	useEffect(() => {
		handleFetchPaymentMethodsCallback()
	}, [
		handleFetchPaymentMethodsCallback
	])

	useEffect(() => {
		dispatch(payment.setPaymentMethod(null))

		const datacheckout = {
			event: 'eec.checkout',
			ecommerce: {
				checkout: {
					actionField: {
						step: 1,
						option: ''
					}
				}
			}
		}

		handleGTM(datacheckout)

		return () => ({})
	}, [
		dispatch,
		handleGTM
	])

	function handleNewItem() {
		if (!newCard) {
			setNewCard(true)
		} else {
			setNewCard(false)
		}
	}

	function handleCloseDialogPayback() {
		setVisiblePayback(false)
	}

	const handlePaybackSelect = () => {
		dispatch(payment.setPaymentPayback(Number(paybackValue)))
		handleCloseDialogPayback()

		if (isMobile) {
			setTimeout(() => {
				history.push(`/cart${URLParameters}`)
			}, 1000)
		}
	}

	function handleOpenDialogPayback() {
		setVisiblePayback(true)
		setPaymentPaybackValue(0)
	}

	function handleCard({ selectCard, isOffline = false }) {
		if (selectCard.brand === 'Dinheiro') {
			handleOpenDialogPayback()

			dispatch(payment.setPaymentMethod({
				...selectCard,
				isOfflinePayment: isOffline
			}))

			return
		} else {
			dispatch(payment.setPaymentPayback(0))
			setPaymentPaybackValue(0)

			dispatch(payment.setPaymentMethod({
				...selectCard,
				isOfflinePayment: isOffline
			}))

			const selectedPaymentMethod = (store.getState().payment && store.getState().payment.selectedPaymentMethod) || {}

			if (isMobile && selectedPaymentMethod && selectedPaymentMethod.paymentType !== 19) {
				setTimeout(() => {
					history.push(`/cart${URLParameters}`)
				}, 1000)
			}
		}
	}

	async function handleAddNewCard() {
		if (cardBrandTicket) {
			await addTicketCard()
		} else {
			await postNewCard()
		}
	}

	async function handleDeleteCard(card) {
		await deleteCard(card)
		/* if (result && result.success) {
			setSelectRemoveCard(null)
			handleCloseDialogDeleteCard()
			fetchPaymentMethods()
			/* setNotificationDelete({
				type: 'positive',
				text: 'O cartão foi removido com sucesso!'
			})
			setTimeout(() => {
				setNotificationDelete(null)
			}, 7500)
		} else {
			setSelectRemoveCard(null)
			handleCloseDialogDeleteCard()
			/* setNotificationDelete({
				type: 'negative',
				text: 'Não foi posivel remover o cartão! Falha no serviço.'
			})
			setTimeout(() => {
				setNotificationDelete(null)
			}, 7500)
		} */
	}

	function handleCardNumber(args) {
		postCardNumber(args)
	}

	function postCardNumber(args) {
		setCardNumber(args)
	}

	function handleCardDate(args) {
		postCardDate(args)
	}

	function postCardDate(args) {
		setCardDate(args)
	}

	function handleCardBirth(args) {
		postCardBirth(args)
	}

	function postCardBirth(args) {
		setCardBirth(args)
	}

	function handleCardName(args) {
		postCardName(args)
	}

	function postCardName(args) {
		setCardName(args)
	}

	function handleCardCPF(args) {
		postCardCPF(args)
	}

	function postCardCPF(args) {
		setCardCPF(args)
	}

	function handleCardBrandId(args) {
		const brandIdSelected = getCardBrand(onlyNumbers(args)) || ''
		const nameBrand = CARD_BRANDS_TAG[brandIdSelected.id] || ''

		if (nameBrand && nameBrand === 'ticket') {
			setCardBrandTicket(true)
		} else {
			setCardBrandTicket(false)
		}

		setSelectCardBrandId(getCardBrandImageById(brandIdSelected.id))
		postCardBrandId(args)
	}

	function postCardBrandId(args) {
		setCardBrandId(args)
	}

	function handleCardCVV(args) {
		postCardCVV(args)
	}

	function postCardCVV(args) {
		setCardCVV(args)
	}

	function handleCloseDialogDeleteCard() {
		setDeleteModalCard(false)
	}

	function handleOpenModal() {
		setDeleteModalCard(true)
	}

	return <Wrapper id='payment-methods'>
		{loading.includes('payment-methods') &&
			<LoadingWrapper>
				<CustomLoading
					type={'spin'}
					id='default-loading'
					height={40}
					width={40}
				/>
			</LoadingWrapper>}

		{!loading.includes('payment-methods') && <>
			<ContentWrapper id='payment-methods-content'>
				<Notification id='payment-methods-content-notification' />

				{newCard && <>
					<ActionContainer className={`top`}>
						<Button onClick={() => {
							dispatch(setNotification(null))
							handleNewItem(false)
						}}>
							Cancelar
						</Button>
					</ActionContainer>

					<NewCard
						number={cardNumber}
						name={cardName}
						date={cardDate}
						CPF={cardCPF}
						CVV={cardCVV}
						birth={cardBirth}

						handleNumber={handleCardNumber}
						handleName={handleCardName}
						handleDate={handleCardDate}
						handleCPF={handleCardCPF}
						handleBrandId={handleCardBrandId}
						handleCVV={handleCardCVV}
						handleNext={handleAddNewCard}
						cardBrandTicket={cardBrandTicket}
						handleBirth={handleCardBirth}
						selectCardBrandId={selectCardBrandId}
					/>
				</>}

				{!newCard && <PaymentMethodsDisplay
					context={context}
					data={[
						onlineMethods,
						onlineCards,
						offlineMethods
					]}
					handleNewItem={handleNewItem}
					handleCard={handleCard}
					handleAddNewCard={handleAddNewCard}
					handleOpenModal={handleOpenModal}
					setSelectRemoveCard={setSelectRemoveCard}
					isPayment={isPayment}
				/>}

				{/* {!newCard && ((onlineCards && onlineCards.length <= 0) && (offlineMethods && offlineMethods.length <= 0)) && <>
          <Content>
            <NoCardIcon>
              <NoCards />
            </NoCardIcon>

            <H4>Você ainda não possui nenhum cartão cadastrado!</H4>

            <Paragraph>Adicione seu primeiro meio de pagamento! :)</Paragraph>
          </Content>
        </>} */}

				<Dialog
					onClose={() => {
						setSetOpenDialogPix(false)
						dispatch(payment.setPaymentMethod({}))
					}}
					className='address'
					aria-labelledby='address-dialog'
					open={selectedPaymentMethod && selectedPaymentMethod.paymentType === 19 && openDialogPix}
					maxWidth={'sm'}
					TransitionComponent={Transition}
				>
					<Pix />
				</Dialog>

				<CustomModalNotification
					openModal={deleteModalCard}
					title={'Excluir Cartão'}
					content={'Você tem certeza que deseja excluir esse cartão?'}
					handleCloseModal={handleCloseDialogDeleteCard}
					size={'xs'}
					fullWidth={true}
					actions={[{
						label: 'Sim',
						classes: 'primary',
						handleClick: () => {
							handleDeleteCard(selectRemoveCard)
						}
					}, {
						label: 'Não',
						classes: 'secondary',
						handleClick: () => {
							handleCloseDialogDeleteCard(false)
						}
					}]}
				/>

				<CustomModalNotification
					openModal={visiblePayback}
					title={'Precisa de Troco?'}
					content={'Se precisar de troco informe abaixo o valor que vai pagar para levarmos o seu troco.'}
					handleCloseModal={handleCloseDialogPayback}
					size={'xs'}
					fullWidth={true}
					input={true}
					inputValue={paybackValue}
					setInputValue={setPaymentPaybackValue}
					inputType="number"
					inputPlaceholder="R$ 0,00"
					actions={[{
						label: 'Confirmar',
						classes: 'primary',
						handleClick: () => {
							handlePaybackSelect()
						}
					}, {
						label: 'Não Preciso de Troco!',
						classes: 'secondary',
						handleClick: () => {
							handleCloseDialogPayback(false)
							setPaymentPaybackValue(0)
						}
					}]}
				/>
			</ContentWrapper>
		</>}
	</Wrapper >
}

PaymentMethodsComponent.propTypes = {
	context: PropTypes.shape({}),
	setNotification: PropTypes.func,
	postNewCard: PropTypes.func,
	isPayment: PropTypes.bool,
	addTicketCard: PropTypes.func,
	setCardName: PropTypes.func,
	setCardNumber: PropTypes.func,
	setCardDate: PropTypes.func,
	setCardBirth: PropTypes.func,
	setCardBrandId: PropTypes.func,
	setCardCPF: PropTypes.func,
	setCardCVV: PropTypes.func,
	deleteCard: PropTypes.func,
	handleGTM: PropTypes.func,
	URLParameters: PropTypes.string,
	setPixRequest: PropTypes.func,
	setPixType: PropTypes.func,
	card: PropTypes.shape({
		name: PropTypes.string,
		number: PropTypes.string,
		date: PropTypes.string,
		CPF: PropTypes.string,
		CVV: PropTypes.string,
		birth: PropTypes.string
	})
}

const mapStateToProps = (state) => {
	return {
		loading: state.loading.loading || [],
		URLParameters: (state.main.URLParameters) || ''
	}
}

const GetConnection = connect(mapStateToProps, {
	setLoading: loading.setLoading,
	setCard: payment.setCard,
	fetchPaymentMethods: payment.fetchPaymentMethods,
	postNewCard: payment.postNewCard,
	addTicketCard: payment.postTicketCard,
	setCardName: payment.setCardName,
	setPixRequest: payment.setPixRequest,
	setPixType: payment.setPixType,
	setCardNumber: payment.setCardNumber,
	setCardDate: payment.setCardDate,
	setCardBirth: payment.setCardBirth,
	setCardBrandId: payment.setCardBrandId,
	setCardCPF: payment.setCardCPF,
	setCardCVV: payment.setCardCVV,
	deleteCard: payment.deletePaymentMethod,
	handleGTM
})

export const PaymentMethods = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'payment' })
)(PaymentMethodsComponent))
