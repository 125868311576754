import {
	CARD_BRANDS_TAG
} from '../enum/payment'

interface MundipaggAccessTokenData {
	success: boolean
	message: string
	accessToken: string
	userId: string
}

interface Card {
	id: number
	data: string
	brand: string
	brandId: number
	paymentType: number
	cvvRequired: boolean
	cardType: number
	active: boolean
	discount: boolean
	discountPercentage: number
	discountMaxValue: number,
	image: String
}

interface deleteCardResponse {
	success: boolean,
	message: string
}

interface PaymentMethodsData {
	selectedPaymentMethod: PaymentMethodsDataSelectedCard
	availableOnlineMethods: PaymentMethodsDataCard[],
	availableOnlineCards: PaymentMethodsDataCard[],
	availableOfflineMethods: PaymentMethodsDataCard[],
	success: boolean
}

interface PaymentMethodsDataCard {
	id: number
	number: string
	brand: string
	brandName: string
	brandId: number
	cvv: number | string
	cvvIsRequired: boolean
	status: boolean
	paymentType: number
}

interface PaymentMethodsDataSelectedCard {
	id: number
	number: string
	brand: string
	brandName: string
	brandId: number
	cvv: number | string
	cvvIsRequired: boolean
	status: boolean
	paymentType: number
}

interface AddCardResponse {
	success: boolean
	message: string
}

interface AddCardData {
	mundipaggCardId: string
	mundipaggUserId: string
	digits: number
	firstDigits: number
	brandId: number
	type: number
	cpf: number
	expMonth: number
	expYear: number
}

interface PixPaymentDataData {
	qrCode: string
	qrCodeUrl: string,
	limitTime: number,
	items: any
}

interface PixPaymentStatusData {
	paymentError?: string
	message: string
	paymentStatus: number
	success: boolean
	orderErrorType: number
}

const mapApiCardToModel = (card: any): Card => {
	return {
		id: card['CartaoId'],
		data: card['Dados'],
		brand: card['Bandeira'],
		brandId: card['BandeiraId'],
		image: card['Imagem'],
		paymentType: card['TipoPagamento'],
		cvvRequired: card['PedirCvv'],
		cardType: card['TipoCartao'],
		active: card['Ativo'],
		discount: card['Desconto'],
		discountPercentage: card['ValorDesconto'],
		discountMaxValue: card['ValorMaximo']
	}
}

export const mapPaymentMethodsData = (response: any): PaymentMethodsData => {
	const onlineMethods =
		response['CartoesUsuario'] &&
		response['CartoesUsuario'].filter((card: any) => card['Tipo'] === 'Pix')

	const availableOnlineMethods = onlineMethods && onlineMethods[0] && onlineMethods[0]['Cartoes']
		.filter((card: any) => card['Ativo'] === true)
		.map((card: any): Card => mapApiCardToModel(card))

	const onlineCards =
		response['CartoesUsuario'] &&
		response['CartoesUsuario'].find((card: any) => card['Tipo'] === 'Cartão de Crédito e Vale Refeição')

	const availableOnlineCards = onlineCards && onlineCards['Cartoes']
		.filter((card: any) => card['Ativo'] === true)
		.map((card: any): Card => mapApiCardToModel(card))

	const offlineMethods =
		response['PagamentosNaEntrega'] &&
		response['PagamentosNaEntrega'].find((card: any) => card['Tipo'] === 'Pagamento Presencial')

	const availableOfflineMethods = offlineMethods &&
		offlineMethods['Cartoes']
			.filter((card: any) => card['Ativo'] === true)
			.map((card: any): Card => mapApiCardToModel(card))

	const selectedPaymentMethod = {
		id: response['CartaoSelecionado'] && response['CartaoSelecionado']['CartaoId'],
		number: response['CartaoSelecionado'] && response['CartaoSelecionado']['Dados'],
		brand: response['CartaoSelecionado'] && response['CartaoSelecionado']['Imagem'],
		brandName: response['CartaoSelecionado'] && response['CartaoSelecionado']['Bandeira'],
		brandId: response['CartaoSelecionado'] && response['CartaoSelecionado']['BandeiraId'],
		cvv: '',
		cvvIsRequired: response['CartaoSelecionado'] && response['CartaoSelecionado']['PedirCvv'],
		status: response['CartaoSelecionado'] && response['CartaoSelecionado']['Ativo'],
		paymentType: response['CartaoSelecionado'] && response['CartaoSelecionado']['TipoPagamento']
	}

	return {
		success: response['Sucesso'],
		availableOnlineMethods,
		availableOnlineCards,
		availableOfflineMethods,
		selectedPaymentMethod
	}
}

export const mapMundipaggAccessTokenData = (data: any): MundipaggAccessTokenData => {
	return {
		success: data['Sucesso'],
		message: data['Mensagem'],
		accessToken: data['AccessToken'],
		userId: data['UsuarioIdMundiPagg'],
	}
}

export const mapCardToMundipaggApiRequest = (data: any): any => {
	return {
		number: data.number,
		holder_name: data.name,
		holder_document: data.cpf,
		exp_month: data.date && data.date.slice(0, 2),
		exp_year: data.date && data.date.slice(-2),
		brand: CARD_BRANDS_TAG[data.brandId],
		cvv: data.CVV,
		private_label: false,
		options: {
			verify_card: false
		}
	}
}

export const mapAddTicketCardRequestToApi = (card: any): any => {
	return {
		'DataNascimento': card.birth,
		'Numero': card.number,
		'Portador': card.name
	}
}

export const mapAddTicketCardResponse = (response: any): AddCardResponse => {
	return {
		success: response['Sucesso'],
		message: response['Mensagem']
	}
}


export const mapDeleteCardRequestToApi = (card: Card): any => {
	return {
		'CartaoId': card.id,
		'BandeiraId': card.brandId
	}
}

export const mapDeleteCardResponse = (response: any): deleteCardResponse => {
	return {
		success: response['Sucesso'],
		message: response['Mensagem']
	}
}

export const mapAddCardRequestToApi = (data: AddCardData): any => {
	return {
		'CartaoIdMundiPagg': data.mundipaggCardId,
		'UsuarioIdMundiPagg': data.mundipaggUserId,
		'Digitos': data.digits,
		'Bandeira': data.brandId,
		'TipoCartao': data.type,
		'Documento': data.cpf,
		'PrimeirosDigitos': data.firstDigits,
		'UltimosDigitos': data.digits,
		'MesValidade': data.expMonth,
		'AnoValidade': data.expYear
	}
}

export const mapAddCardResponse = (data: any): AddCardResponse => {
	return {
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}

export const mapPixPaymentDataData = (data: any): PixPaymentDataData => {
	const items = data['PedidoItems'] && data['PedidoItems'].length && data['PedidoItems'].map((mappedItem: any) => {
		return {
			productId: mappedItem['ProdutoId'],
			product: mappedItem['Produto'],
			quantity: mappedItem['Quantidade'],
			description: mappedItem['Descricao'],
			value: mappedItem['Valor'],
			orderId: mappedItem['PedidoId'],
			additionalText: mappedItem['TextoAcompanhamento'],
			notes: mappedItem['Observacao'],
			additionals: mappedItem['Acompanhamentos'],
			fractionalQuantity: mappedItem['QuantidadeFracionada']
		}
	})

	return {
		qrCode: data['QRCode'],
		qrCodeUrl: data['QRCodeUrl'],
		limitTime: data['TempoLimite'],
		items: items
	}
}

export const mapPixPaymentStatusData = (data: any): PixPaymentStatusData => {
	return {
		paymentError: data['ErroPagamento'],
		message: data['Mensagem'],
		paymentStatus: data['StatusPagamento'],
		success: data['Sucesso'],
		orderErrorType: data['TipoFalhaPedido']
	}
}
