import React, {
	useContext,
	useEffect
} from 'react'
import PropTypes from 'prop-types'
import {
	connect,
	useStore
} from 'react-redux'
import { Helmet } from 'react-helmet'

import history from '../../../history'

import {
	useStoreSettings,
	useLayout,
	useSidebar
} from '../../hooks'

import {
	useDeviceLayout
} from '../../hooks'

import {
	pipe,
	GetContent
} from '../../../domain/helpers'

import {
	DefaultImage as Image,
	CustomIcon
} from '../../components'

import {
	Cart,
	CartContainer,
	MainHeader,
	PageContainer,
	PageContainerHeader,
	PageContainerMain,
	PageContainerSide,
	Navigation,
	PaymentMethods
} from '../..'

import {
	H1,
	Wrapper,
	BackButton
} from './styles'

export const Context = React.createContext({})

export function PaymentPage(props) {
	const store = useStore()

	const userHistory = store.getState().main.userHistory || {}
	const URLParameters = store.getState().main.URLParameters || ''

	const {
		theme,
		location
	} = props

	const storeImage = theme.headerImage
	const storeLogo = theme.logoImage

	const {
		url,
		title,
		description
	} = useStoreSettings()

	const context = useContext(Context)

	const isMobile = useDeviceLayout({
		isMobile: true
	})

	const {
		layoutMode,
		layoutModeEnum,
		handleLayoutMode
	} = useLayout()

	const {
		sidebarContent,
		sidebarOpened,
		sidebarContentEnum,
		handleSidebarContent,
		handleSidebarOpened
	} = useSidebar()

	useEffect(() => {

	}, [])

	return (
		<Wrapper id={'payment'}>
			<Helmet>
				<title>{`${title} - Pagamento`}</title>
				<meta name='description' content={description} />
				<link rel='canonical' href={`${url}/contact-us`} />
			</Helmet>

			<PageContainer
				isSideOpen={sidebarOpened}
				mode={layoutMode}
			>
				<PageContainerHeader isMobile={isMobile}>
					<MainHeader
						mode='full'
						theme={theme}
						homeOnClick={() => {
							handleLayoutMode(layoutModeEnum.default)
							handleSidebarContent(sidebarContentEnum.default)
							handleSidebarOpened(false)
						}}
						userClicked={() => {
							if (sidebarOpened && sidebarContent === sidebarContentEnum.nav) {
								handleLayoutMode(layoutModeEnum.default)
								handleSidebarContent(sidebarContentEnum.default)
								handleSidebarOpened(false)
							} else {
								handleLayoutMode(layoutModeEnum.headerFullContent)
								handleSidebarContent(sidebarContentEnum.nav)
								handleSidebarOpened(true)
							}
						}}
						cartClicked={() => {
							if (sidebarOpened && sidebarContent === sidebarContentEnum.cart) {
								handleLayoutMode(layoutModeEnum.default)
								handleSidebarContent(sidebarContentEnum.default)
								handleSidebarOpened(false)
							} else {
								handleLayoutMode(layoutModeEnum.sideFullContent)
								handleSidebarContent(sidebarContentEnum.cart)
								handleSidebarOpened(true)
							}
						}}
						isSidebarOpen={sidebarOpened}
					/>
				</PageContainerHeader>
				<PageContainerMain opened={sidebarOpened}>
					<Image src={storeImage} alt="" />

					<H1>Formas de Pagamento</H1>

					<BackButton className='back-button' onClick={() => {
						const {
							back
						} = userHistory

						if (back) {
							history.push(`/${back}${URLParameters}`)

							return
						}
					}}>
						<CustomIcon name='AngleLeft' />
					</BackButton>

					<PaymentMethods
						context={context}
						isPayment={true}
					/>
				</PageContainerMain>
				<PageContainerSide opened={sidebarOpened}>
					{!isMobile && layoutMode === layoutModeEnum.sideFullContent && <CartContainer logo={storeLogo}>
						<Cart
							mode='simple'
							screenMobile={isMobile}
							handleFinish={() => { }}
							handleClose={() => { }}
							pathName={location.pathname}
						/>
					</CartContainer>}

					{layoutMode === layoutModeEnum.headerFullContent && <Navigation
						currentRoute='contact-us'
					/>}
				</PageContainerSide>
			</PageContainer>
		</Wrapper>
	)
}

PaymentPage.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string
	}),
	theme: PropTypes.shape({
		headerImage: PropTypes.string,
		logoImage: PropTypes.string
	})
}

const mapStateToProps = (state) => {
	return state
}

const GetConnection = connect(mapStateToProps, {})

export const Payment = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'payment' })
)(PaymentPage))
