import styled from 'styled-components'

export const Wrapper = styled.div``

export const H3 = styled.h3`
	padding: 1rem;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#CCC' };
	background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFifthColor) || '#CCC' };
	border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC' } .0625rem solid;
	border-radius: 1rem;
	font-size: 1rem;
	text-transform: uppercase;
`

export const List = styled.ul`
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex-gap: 1rem;
	justify-content: space-between;
`

export const ListItem = styled.li`
	display: flex;
	align-items: center;
	flex: 1;
	max-width: 100%;
	min-width: 100%;
	margin-bottom: 1rem;
	border-bottom: #CCC .0625rem solid;
	padding: 1rem;

	@media (min-width: 60rem) {
		max-width: calc(50% - 1rem);
		min-width: calc(50% - 1rem);
	}
`

export const ListItemImage = styled.img`
	margin-right: 1rem;
	min-width: 2rem;
	max-width: 2rem;
	min-height: auto;
	max-height: 2rem;
`

export const ListItemContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

export const ListItemActions = styled.div`
  flex: 0 0 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0;

    input {
      height: 1.5rem;
      width: 1.5rem;
      font-size: 1.5rem;
      margin: 0;
      opacity: 0;
      z-index: 3;
      cursor: pointer;
    }

    label {
      height: 1.5rem;
      width: 1.5rem;

      &:before {
        margin: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
      }

      &:after {
        top: .45rem;
        left: .35rem;
      }
    }
  }

  svg {
    margin: 0 0 0 .5rem;
  }
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
	justify-content: flex-end;
  min-height: 3.5rem;
	max-height: 3.5rem;
  max-width: 100%;
  min-width: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .875rem;
    flex: 1;
		padding: 1rem;
    min-height: 3.5rem;
		max-height: 3.5rem;
		max-width: 100%;
	  min-width: 100%;
		box-sizing: border-box;

    > svg {
      margin-left: 1rem;
    }
  }

	@media (min-width: 60rem) {
		button {
			max-width: 50%;
			min-width: 50%;
		}
	}
`
