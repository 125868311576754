export default ({ orderRepository, maps, auth }) => {
	return async (payload) => {
		const { onSuccess, onError, data, actions, dispatch } = payload;

		try {
			const orders = await orderRepository.fetchOrders({
				data,
				actions,
				maps,
				dispatch,
				auth: auth(),
			});

			onSuccess({ orders });
		} catch (e) {
			console.log(e);

			onError();
		}
	};
};
