import React from "react";
import PropTypes from "prop-types";

import { copyTextToClipboard } from "../../../../domain/helpers";

import { ReactComponent as CopyIcon } from "../../../assets/icons/icon_copy.svg";

import { Wrapper, CopyButton, Paragraph } from "./styles";

export function CopyPasteCode(props) {
	const { text } = props;

	return (
		<Wrapper>
			<Paragraph>{text}</Paragraph>

			<CopyButton
				onClick={() => {
					copyTextToClipboard(text);
				}}
			>
				<CopyIcon />
			</CopyButton>
		</Wrapper>
	);
}

CopyPasteCode.propTypes = {
	text: PropTypes.string,
};
