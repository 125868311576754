import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundHeader) || '#333'};
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  min-height: 4.225rem;
  max-height: 4.225rem;
  min-width: 100%;
  max-width: 100%;
	flex-direction: row;
`

export const LeftWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex: 1;

	> button {
		margin: 0;
		display: flex;
		border: 1px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000' };
		background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#000' };
		color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'};
		padding: .5rem;
		cursor: pointer;
		font-weight: bold;
		border-radius: 1rem;
		font-size: .8rem;
		max-width: 5rem;
		min-width: 5rem;
		box-sizing: border-box;
		min-height: 3rem;
		max-height: 3rem;
		align-items: center;
		justify-content: center;

		svg {
			width: 1.5rem;
			height: 1.1rem;
			margin: 0 .2rem;
			path {
				fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
				stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
			}
		}
	}
`

export const CenterWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	flex-direction: column;
`
export const RightWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1;
`

export const ModalityContainer = styled.div`
  margin: 0 1rem;
  display: flex;
	${props => {
    if (props.storeDelivery) {
      return `border: 1px solid #CECECE;`
    } else {
      return `border: none;`
    }
  }}
	min-height: 2.25rem;
	max-height: 2.25rem;
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#000'};
  border-radius: .5rem;

  &.btn-group button {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'};
    padding: 5px 15px;
    cursor: pointer;
    float: left;
    font-weight: bold;
    border-radius: .5rem;
    font-size: .8rem;

    &.active{
      background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.modalityButtonColor) || '#000'};
      border-radius: .5rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#000'};
      box-shadow:2px 2px 5px 1px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.shadowModalityButtonColor) || '#000'};
      z-index: 1;
    }
  }

  @media (min-width: 60rem) {
    ${props => {
      if (props.opened) {
        return `display: none;`
      } else {
        return `display: unset;`
      }
    }}
  }

  @media (min-width: 70rem) {
    display: unset;
  }
`

export const CustomButton = styled.button``
