import { combineReducers } from "redux";

import main from "./reducers/main";

import {
	addressReducer,
	cartReducer,
	layoutReducer,
	loadingReducer,
	menuReducer,
	paymentReducer,
	sidebarReducer,
	signUpReducer,
	storeReducer,
	userAddressReducer,
	userReducer,
} from ".";

export default combineReducers({
	address: addressReducer,
	cart: cartReducer,
	layout: layoutReducer,
	loading: loadingReducer,
	menu: menuReducer,
	payment: paymentReducer,
	sidebar: sidebarReducer,
	signUp: signUpReducer,
	store: storeReducer,
	user: userReducer,
	userAddress: userAddressReducer,

	main,
});
