import React, {
	useEffect,
	useState,
} from 'react'
import {
	connect,
	useStore,
} from 'react-redux'
import {
	withTheme
} from 'styled-components'
import {
	Dialog,
	Slide
} from '@material-ui/core'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import {
	useStoreSettings,
	usePhoneValidation
} from '../hooks'

import {
	pipe,
	GetContent,
	caculateProductsQuantity,
	maskCpf
} from '../../domain/helpers'

import history from '../../history'

import { ReactComponent as LocationIcon } from './../assets/icons/icon_checkout_address.svg'
import { ReactComponent as EditIcon } from './../assets/icons/icon_edit_fill.svg'
import { ReactComponent as BasketIcon } from '../assets/icons/icon_basket.svg'
import { ReactComponent as MotoSvg } from '../assets/moto.svg'

import {
	CustomIcon,
	CustomLoading
} from '../components'

import { Icon } from '../components/common'

import {
	useCustomLayout,
	useDeviceLayout
} from '../hooks'

import {
	setQuantity,
	setSelectedDeliveryDate,
	setCart,
	setUserHistory,
	setNotification,
	setCVV,
	setProductsRemoved,
	handleGTM,
	setScheduling,
	setURLParameters
} from '../../redux/actions/main'

import {
	CVV
} from '../payment-methods'

import {
	List,
	Card,
	MinimumValue,
	Coupon
} from '.'

import {
	loading,
	cart,
	coupon,
	order,
	addressActions,
	payment
} from '../../state'

import {
	CouponsList
} from '../coupons/simple'

import { calculateCartTotal } from '../../domain/helpers'


import {
	Notification,
	AddressPostalCode
} from '..'

import {
	Wrapper,
	AddressWrapper,
	Paragraph,
	H1,
	CloseButton,
	ContentWrapper,
	Div,
	H3,
	OverlayWrapper,
	CartHeaderWrapper,
	CartFooterWrapper,
	ButtonIcon,
	IconButtonContainer,
	ButtonIconLabel,
	IconButtonCartsItemsAmount,
	EditButton,
	ContainerCoupon,
	BoxFreeShipping
} from './styles'
import { Pix } from './Pix'
import { DeliveryDateSelecion } from '../order-confirmation/Details/DeliveryDateSelecion'

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

export function CartPixPage(props) {
	const store = useStore()

	const selectedPaymentMethod = (store.getState().payment && store.getState().payment.selectedPaymentMethod) || {}
	const pixData = (store.getState().payment && store.getState().payment.pixPaymentData) || null

	const {
		loading,
		setLoading,
		accessToken,
		userName,
		className,
		storeName,
		storeAddress,
		products,
		deliveryFee,
		addOrder,
		setQuantity,
		modalityId,
		pickupMinimumValue,
		deliveryMinimumValue,
		login,
		setCart,
		setUserHistory,
		setNotification,
		setCVV,
		storeStatus,
		params,
		handleClose,
		theme,
		coupon,
		couponField,
		device,
		isQuantityEditable,
		pathName = '',
		handleCloseDialogCart,
		mode,
		cartCoupon,
		handleNoToken,
		addCoupon,
		setCoupon,
		setCartCoupon,
		scheduling,
		userCpf,
		setProductsRemoved,
		URLParameters,
		setAddressPostalCodeRequest,
		addressPostalCodeRequest,
		setAddressRequest,
		address,
		setPaymentMethod,
		setPaymentPayback,
		section,
		above,
		setAbove,
		handleGTM,
		storeLoyaltyPoints,
		minForFreeShipping,
		setSelectedDeliveryDate,
		selectedDeliveryDate,
		setScheduling,
		setURLParameters,

		//pix
	} = props

	const {
		brandId,
		brand,
		data: number,
		paymentType,
		cvv
	} = selectedPaymentMethod

	const [orderLoading, setOrderLoading] = useState(false)
	const [contentMotion, setContentMotion] = useState(false)
	const [contentOverlayMotion, setContentOverlayMotion] = useState(false)
	const [CVVReady, setCVVReady] = useState(false)
	const [cartTotal, setCartTotal] = useState(0)
	const [visibleCoupons, setVisibleCoupons] = useState(false)
	const [aboveCart, setAboveCart] = useState(false)
	const [openDialogPix, setOpenDialogPix] = useState(false)
	const [points, setPoints] = useState(0)
	const [minValueLoyaltyRequired, setMinValueLoyaltyRequired] = useState(false)
	const [deliveryDateSelectionOpened, setDeliveryDateSelectionOpened] = useState(false)
	const [itemsPix, setItemsPix] = useState(false)

	const {
		url,
		title,
		description
	} = useStoreSettings()

	const {
		isValidPhone,
		showPhoneValidation,
	} = usePhoneValidation()

	useCustomLayout({
		device,
		backgroundColor: theme.main.general.primaryColor
	})

	const isMobile = useDeviceLayout({
		isMobile: true
	})

	useEffect(() => {
		if (storeLoyaltyPoints && storeLoyaltyPoints.modelPoints === 1) {
			const sumall = products && products.map(item => item.loyaltyPoints).reduce((prev, curr) => prev + curr, 0);
			setPoints(sumall)
		} else if (storeLoyaltyPoints && storeLoyaltyPoints.modelPoints === 2) {
			setMinValueLoyaltyRequired(true)
			setPoints(storeLoyaltyPoints.points)
		} else {
			setPoints(storeLoyaltyPoints.points)
		}
	}, [storeLoyaltyPoints, products])

	useEffect(() => {
		if (URLParameters === "?viewPix=true" && pixData.items && pixData.items.length > 0) {
			setItemsPix(pixData.items)
		}
	}, [URLParameters, pixData])

	useEffect(() => {
		if (products && products.length && !contentMotion) {
			setContentMotion(true)
		}

		setCartTotal(calculateCartTotal(products))

		if (CVVReady) {
			setContentOverlayMotion(true)
		}
	}, [
		CVVReady,
		products,
		contentMotion
	])

	function postCoupon() {
		addCoupon([() => {
			setCartCoupon({
				error: true
			})
			setCoupon('')
		}, (data) => {
			setCartCoupon({
				code: coupon,
				...data
			})
		}])
	}

	function handleCoupon(data) {
		setCartCoupon({
			...data
		})

		setVisibleCoupons(false)
	}

	function handleAddCoupon() {
		if (!accessToken) {
			if (isMobile) {
				history.push(`/login${URLParameters}`)

				return
			}
		}

		postCoupon()
	}

	function handleRemoveCoupon() {
		setCartCoupon('')
		setCoupon('')
	}

	function handleViewCoupons() {
		setVisibleCoupons(true)
	}

	function handleCloseDialogCoupons() {
		setVisibleCoupons(false)
	}

	function handleCVV(args) {
		postCVV(args)
	}

	function handleCards() {
		if (!accessToken) {
			if (isMobile) {
				history.push(`/login${URLParameters}`)

				return
			}
		}

		if ((!isValidPhone) && login?.subscriptionStatus !== undefined) {
			showPhoneValidation(isMobile)
			return;
		}

		if (isMobile) {
			setUserHistory({
				back: 'cart'
			})


		} else {
			setUserHistory({
				back: 'store'
			})
		}

		history.push(`/cards${URLParameters}`)
	}

	function handleCVVNext() {
		setContentOverlayMotion(false)
		setCVVReady(false)
	}

	function postCVV(args) {
		setCVV(args)
	}

	function handleStartFinish() {
		if (selectedPaymentMethod.paymentType && selectedPaymentMethod.data === 'Pix') {
			setOpenDialogPix(true)
			return
		}

		handleFinish()
	}

	async function handleFinish() {
		setLoading('payment')
		if (storeStatus && storeStatus !== 1) {
			setNotification({
				type: 'warning',
				message: 'Ops! A loja está fechada. Tente em outro horário!!'
			})

			setLoading('')
			return
		}

		setOrderLoading(true)
		setNotification(null)

		await addOrder([{
			name: 'order',
			type: 'error',
			callback: () => {
				setOrderLoading(false)
				setNotification({
					name: isMobile ? 'cart' : (section ? section : 'order-confirmation-cart'),
					type: 'warning',
					message: 'Ocorreu um erro no pagamento.'
				})
			}
		}, {
			name: 'order',
			type: 'success',
			callback: (payload) => {
				setCart({
					products: null
				})
				setPaymentMethod(null)
				setPaymentPayback(0)

				setScheduling(null)

				setSelectedDeliveryDate(null)
				setLoading({ item: 'payment', delete: true })
				//GTM 3.0 - Evento para criação do pedido
				const cart = cartTotal.toFixed(2)
				const finishCart = parseFloat(cart)
				const dataGTM = {
					'event': `purchase`,
					'ecommerce': {
						'currency': 'BRL',
						'store': storeName,
						'value': finishCart || null,
						'shipping': deliveryFee || null,
						'affiliation': storeName || null,
						'transaction_id': payload.orderId || 0,
						'coupon': couponField || null,
						'modalidade_retirada': modalityId === 2 ? 'retirar_na_loja' : 'delivery',
						'items': products.map(function (item) {
							return {
								'item_name': item.name,
								'item_id': item.id,
								'price': item.promotionalValue ? item.promotionalValue : item.value,
								'discount': null,
								'item_category': item.category.name || null,
								'quantity': item.quantity
							}
						})
					}
				}
				handleGTM(dataGTM)

				history.push(`/receipt${URLParameters}`)
			}
		}, {
			name: 'address',
			type: 'error',
			callback: () => {
				setUserHistory({
					next: 'cart',
					back: 'cart'
				})

				setNotification({
					type: 'warning',
					message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
				})

				history.push(`/new-address${URLParameters}`)
				setLoading('')
			}
		}, {
			name: 'address',
			type: 'success',
			callback: () => {
				console.warn('Address created')
				setLoading('')
			}
		}], {
			update: (params && !!params.showCart) || false
		})
	}

	function handleQuantity(args) {
		setQuantity(args)

		if (!args.quantity && products.length === 1 && products[0].quantity === 1 && handleClose && typeof handleClose === 'function') {
			handleClose()
		}
	}

	function handleCloseDialogAddress() {
		setAddressRequest(false)
		setAddressPostalCodeRequest(false)
	}

	const cartMinimumValue = ({
		cartTotal, pickupMinimumValue, deliveryMinimumValue, modalityId
	}) => {
		if (modalityId === 2 && cartTotal < pickupMinimumValue) {
			return false
		}

		if (modalityId === 4 && cartTotal < deliveryMinimumValue) {
			return false
		}

		return true
	}

	const handleGoToNext = (products) => {
		if (products.length) {
			if ((!isValidPhone) && login?.subscriptionStatus !== undefined) {
				showPhoneValidation(isMobile)
				return;
			}

			if (!accessToken) {
				handleNoToken()

				return
			}

			if (isMobile && handleCloseDialogCart && typeof handleCloseDialogCart === 'function') {
				handleCloseDialogCart()
				history.push(`/cards${URLParameters}`)

				return
			}

			//GTM 3.0 - Evento para iniciar o fluxo de confirmação do pedido
			const cart = cartTotal.toFixed(2)
			const finishCart = parseFloat(cart)
			const dataCheckout = {
				'event': `begin_checkout`,
				'ecommerce': {
					'currency': 'BRL',
					'store': storeName,
					'items': products.map(function (item) {
						return {
							'item_name': item.name,
							'item_id': item.id,
							'price': item.value,
							'discount': null,
							'item_category': item.category.name || null,
							'quantity': item.quantity
						}
					}),
					'value': finishCart || null
				}
			}
			handleGTM(dataCheckout)

			history.push(`/payment${URLParameters}`)
		}

		return
	}

	const goToConfirmation = () => {
		setNotification(null)

		history.push(`/order-confirmation${URLParameters}`)
	}

	const schedulingValidation = () => {
		if (scheduling) {
			if (selectedDeliveryDate) {
				return true
			}

			return false
		}

		return true
	}

	const finishButtonDisabled = () => {
		if (accessToken && cartTotal && modalityId && schedulingValidation() && selectedPaymentMethod.data && cartMinimumValue({
			modalityId,
			cartTotal,
			pickupMinimumValue,
			deliveryMinimumValue
		})) {
			return false
		} else {
			return true
		}
	}

	const calcFreeShipping = () => {
		return (minForFreeShipping - cartTotal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
	}


	const getFormatedDate = () => {
		const date = new Date(selectedDeliveryDate.date)
		const week = date.toLocaleDateString('pt-BR', { weekday: 'long' }).split('-')[0]
		const month = date.toLocaleDateString('pt-BR', { month: 'long' })
		const day = date.toLocaleDateString('pt-BR', { day: 'numeric' })
		const year = date.toLocaleDateString('pt-BR', { year: 'numeric' })
		return `${week}, ${day} de ${month} de ${year}, ${selectedDeliveryDate.hour.time}`
	}

	return <Wrapper id='cart' className={className}>
		<Helmet>
			<title>{`${title} - Carrinho de compras`}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={typeof handleCloseDialogCart === 'function' ? `${url}/cart` : `${url}/store`} />
		</Helmet>

		{mode === 'simple' ? null : <CartHeaderWrapper>
			<CloseButton className='close' onClick={() => {
				if (URLParameters === "?viewPix=true") {
					setURLParameters("")
					history.push(`/${URLParameters}`)
					return
				}

				history.push(`/${URLParameters}`)

			}}>
				<CustomIcon name='AngleDown' />
			</CloseButton>

			{login && login.status === true && address ? <AddressWrapper>
				<Icon name='localizationWhite' width='11px' />
				<Paragraph>{address.main}</Paragraph>
			</AddressWrapper> : !orderLoading && <H1>Sacola</H1>}
		</CartHeaderWrapper>}

		<ContentWrapper id='cart-content' className={'motion'}>
			<Notification id='cart-notification' section={`${section ? `${section}` : 'cart'}`} />

			<List
				mode={mode}
				pathName={pathName}
				coupon={cartCoupon || coupon}
				storeName={storeName}
				storeAddress={storeAddress}
				data={URLParameters === "?viewPix=true" ? itemsPix : products}
				deliveryFee={deliveryFee}
				userName={userName}
				handleQuantity={handleQuantity}
				modalityId={modalityId}
				isQuantityEditable={isQuantityEditable}
				setProductsRemoved={setProductsRemoved}
				setAbove={setAbove ? setAbove : setAboveCart}
				handleGTM={handleGTM}
				storeLoyaltyPoints={storeLoyaltyPoints}
				points={points}
				minValueLoyaltyRequired={minValueLoyaltyRequired}
				minForFreeShipping={minForFreeShipping}
				paymentType={paymentType}
			/>

			{paymentType !== 19 && <ContainerCoupon>
				<Coupon
					cartCoupon={cartCoupon}
					coupon={coupon}
					setCoupon={setCoupon}
					handleAddCoupon={handleAddCoupon}
					handleRemoveCoupon={handleRemoveCoupon}
					handleViewCoupons={handleViewCoupons}
				/></ContainerCoupon>}

			{/* bottão de copiar o código pix */}
			{mode === "simple" && isMobile && pathName === '/order-confirmation' && paymentType === 19 &&
				<ButtonIcon onClick={() => console.log("Copiado")}>
					<ButtonIconLabel >Copiar Código</ButtonIconLabel>
				</ButtonIcon>}

			<CartFooterWrapper>
				{scheduling && (selectedDeliveryDate ?
					<Div id='cart-scheduling'>
						<div className='content'>
							<H3>Data agendada</H3>
							<Paragraph>
								{getFormatedDate()}
							</Paragraph>
						</div>
						<div className='edit'>
							<EditButton onClick={
								() => setDeliveryDateSelectionOpened(true)
							}>
								<EditIcon />
							</EditButton>
						</div>
					</Div> :
					<Div id='cart-scheduling-select'>
						<h4>Data agendada</h4>
						<Paragraph onClick={
							() => setDeliveryDateSelectionOpened(true)
						}>Selecione</Paragraph>
					</Div>)}

				{mode === 'simple' ? null : (modalityId === 4 ?
					<Div id='cart-address'>
						<div className='content'>
							<H3>Local de entrega</H3>
							<Paragraph>
								<LocationIcon />
								{address ?
									`${address.main}${(address.number ? `, ${address.number}` : ``)}${(address.complement ? `, ${address.complement}` : ``)}${(address.neighborhood ? `, ${address.neighborhood}` : ``)}` : ''}
							</Paragraph>
						</div>
						<div className='edit'>
							<EditButton onClick={() => {
								setUserHistory({
									back: 'cart'
								})

								if (!accessToken) {
									setAddressPostalCodeRequest(true)
								} else {
									history.push(`/addresses${URLParameters}`)
								}
							}}>
								<EditIcon />
							</EditButton>
						</div>
					</Div> :
					<Div id='cart-address'>
						<div className='content-pickup'>
							<H3>Lembrando que você vai retirar em:</H3>
							<Paragraph>
								<LocationIcon />
								{storeAddress}
							</Paragraph>
						</div>
					</Div>)}

				{(mode === 'simple' || (above ? !above : !aboveCart)) ? null :
					(!selectedPaymentMethod ? <Div className='cart-cards'>
						<Paragraph onClick={() => handleCards()}>
							Selecione uma forma de pagamento
							<EditButton onClick={() => handleCards()}>
								<EditIcon />
							</EditButton>
						</Paragraph>
					</Div> : <Card
						brand={brand}
						number={number}
						paymentType={paymentType}
						brandId={brandId}
						handlePayment={handleCards}
					/>)}

				{mode === 'simple' ? null : userCpf ? <Div id='cart-address'>
					<div className='content'>
						<H3>CPF na nota</H3>
						<Paragraph>
							{maskCpf(userCpf)}
						</Paragraph>
					</div>
				</Div> : <></>}

				{cartTotal && modalityId ? <MinimumValue
					cartTotal={cartTotal}
					modalityId={modalityId}
					minimumValue={modalityId === 2 ? pickupMinimumValue : deliveryMinimumValue}
				/> : null}

				{!pathName && !isMobile &&
					<ButtonIcon disabled={!products.length} onClick={() => handleGoToNext(products)}>
						<IconButtonContainer>
							<IconButtonCartsItemsAmount>{caculateProductsQuantity(products)}</IconButtonCartsItemsAmount>
							<BasketIcon />
						</IconButtonContainer>
						<ButtonIconLabel>Formas de pagamento</ButtonIconLabel>
					</ButtonIcon>}
				{!!deliveryFee && minForFreeShipping && (
					<BoxFreeShipping percent={Math.round(cartTotal * 100 / minForFreeShipping)}>
						<MotoSvg />
						{
							cartTotal < minForFreeShipping ?
								(<p>Faltam apenas <b>{calcFreeShipping()}</b> <br /> para ganhar <b>entrega grátis!</b></p>)
								: (<p>Oba! Você ganhou <b>entrega grátis!</b></p>)
						}
					</BoxFreeShipping>
				)}

				{!isMobile && pathName === '/payment' &&
					<ButtonIcon disabled={finishButtonDisabled() || (!selectedPaymentMethod.data && !selectedPaymentMethod.paymentType)} onClick={() => goToConfirmation()}>
						<ButtonIconLabel >Continuar</ButtonIconLabel>
					</ButtonIcon>}

				{(pathName && pathName === '/order-confirmation') && !pixData && <ButtonIcon id='finish-purchase-button' disabled={finishButtonDisabled()} onClick={() => {
					handleStartFinish()
				}}>
					<ButtonIconLabel >{loading.includes('payment') ?
						<CustomLoading
							color={'#fff'}
							type={'spin'}
							id='default-loading'
							height={30}
							width={30} /> :
						'Finalizar Compra'}</ButtonIconLabel>
				</ButtonIcon>}

				{isMobile && <ButtonIcon disabled={finishButtonDisabled()} onClick={() => {
					handleStartFinish()
				}}>
					<ButtonIconLabel>{loading.includes('payment') ?
						<CustomLoading
							color={'#fff'}
							type={'spin'}
							id='default-loading'
							height={30}
							width={30} /> :
						'Finalizar Compra'}</ButtonIconLabel>
				</ButtonIcon>}
			</CartFooterWrapper>

			<Dialog
				onClose={() => handleCloseDialogCoupons()}
				aria-labelledby='coupons-list-dialog'
				open={visibleCoupons}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				fullWidth={true}
			>
				<CouponsList theme={theme} handleCoupon={handleCoupon} handleCloseCoupon={setVisibleCoupons} />
			</Dialog>

			<Dialog
				onClose={() => setDeliveryDateSelectionOpened(false)}
				aria-labelledby='delivery-date-selection'
				open={deliveryDateSelectionOpened}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				PaperProps={{
					style: {
						width: '25rem',
						borderRadius: '.75rem'
					}
				}}
				fullWidth={false}
			>
				<DeliveryDateSelecion
					selectedDeliveryDate={selectedDeliveryDate}
					close={() => setDeliveryDateSelectionOpened(false)}
				/>
			</Dialog>

			<Dialog
				onClose={() => {
					setAddressPostalCodeRequest(false)
				}}
				className='addresses-postal-code address-root-dialog'
				aria-labelledby='addresses-postal-code-dialog'
				open={addressPostalCodeRequest}
				maxWidth={'sm'}
				TransitionComponent={Transition}
				fullScreen={false}
				fullWidth={false}
			>
				<AddressPostalCode
					handleCloseDialogAddress={handleCloseDialogAddress}
					title={accessToken ? 'Alterar local de entrega?' : ''}
				/>
			</Dialog>

			<OverlayWrapper className={contentOverlayMotion ? 'motion' : ''}>
				<Div className='background' onClick={() => {
					if (contentOverlayMotion) {
						setContentOverlayMotion(false)
						setCVVReady(false)
					}
				}} />

				{CVVReady ? <CVV cvv={cvv} handleCVV={handleCVV} handleNext={handleCVVNext} /> : null}
			</OverlayWrapper>

			<Dialog
				onClose={() => { setOpenDialogPix(false) }}
				className='address'
				aria-labelledby='address-dialog'
				open={selectedPaymentMethod && selectedPaymentMethod.paymentType === 19 && openDialogPix}
				maxWidth={'sm'}
				TransitionComponent={Transition}
			>
				<Pix setOpenDialogPix={setOpenDialogPix} setCart={setCart} />
			</Dialog>
		</ContentWrapper>
	</Wrapper>
}

CartPixPage.propTypes = {
	loading: PropTypes.arrayOf(PropTypes.string),
	setLoading: PropTypes.func,
	accessToken: PropTypes.string,
	userName: PropTypes.string,
	className: PropTypes.string,
	storeName: PropTypes.string,
	storeAddress: PropTypes.string,
	userCard: PropTypes.shape({
		brandId: PropTypes.number,
		brand: PropTypes.string,
		data: PropTypes.shape({}),
		paymentType: PropTypes.number,
		cvv: PropTypes.bool
	}),
	products: PropTypes.arrayOf(PropTypes.shape({
		quantity: PropTypes.number
	})),
	deliveryFee: PropTypes.number,
	addOrder: PropTypes.func,
	setQuantity: PropTypes.func,
	modalityId: PropTypes.number,
	pickupMinimumValue: PropTypes.number,
	deliveryMinimumValue: PropTypes.number,
	login: PropTypes.shape({
		status: PropTypes.bool
	}),
	setCart: PropTypes.func,
	setUserHistory: PropTypes.func,
	setNotification: PropTypes.func,
	setCVV: PropTypes.func,
	storeStatus: PropTypes.number,
	params: PropTypes.shape({
		showCart: PropTypes.bool
	}),
	handleClose: PropTypes.func,
	theme: PropTypes.shape({
		main: PropTypes.shape({
			general: PropTypes.shape({
				primaryColor: PropTypes.string
			})
		})
	}),
	coupon: PropTypes.string,
	device: PropTypes.string,
	isQuantityEditable: PropTypes.func,
	pathName: PropTypes.string,
	handleCloseDialogCart: PropTypes.func,
	mode: PropTypes.string,
	cartCoupon: PropTypes.string,
	handleNoToken: PropTypes.func,
	addCoupon: PropTypes.func,
	setCoupon: PropTypes.func,
	setCartCoupon: PropTypes.func,
	setSelectedCoupon: PropTypes.func,
	scheduling: PropTypes.bool,
	setSelectedDeliveryDate: PropTypes.func,
	userCpf: PropTypes.string,
	setProductsRemoved: PropTypes.func,
	URLParameters: PropTypes.string,
	setAddressPostalCodeRequest: PropTypes.func,
	addressPostalCodeRequest: PropTypes.bool,
	setAddressRequest: PropTypes.func,
	addressRequest: PropTypes.bool,
	address: PropTypes.shape({
		main: PropTypes.string,
		number: PropTypes.string,
		complement: PropTypes.string,
		neighborhood: PropTypes.string
	}),
	setPaymentMethod: PropTypes.func,
	setPaymentPayback: PropTypes.func,
	section: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		accessToken: state.user.accessToken,
		card: state.main.card || '',
		userName: state.user.name || '',
		storeName: state.store.store && state.store.store.name,
		storeAddress: state.store.store && state.store.store.address,
		// products: (state.main.cart && state.main.cart.products) || [],
		deliveryFee: (state.store.store && state.store.store.deliveryFee) || 0,
		deliveryMinimumValue: (state.store.store && state.store.store.deliveryMinimumValue) || 0,
		pickupMinimumValue: (state.store.store && state.store.store.pickupMinimumValue) || 0,
		adHoc: state.store.store && state.store.store.adHoc,
		modalityId: state.main.modality && state.main.modality.id,
		login: state.main.login || {},
		params: state.main.params || {},
		storeStatus: state.store.store && state.store.store.status,
		coupon: state.main.couponField || '',
		couponField: state.main.couponField || '',
		cartCoupon: (state.main.cart && state.main.cart.coupon) || '',
		loading: (state.store.store && state.loading.loading) || [],
		device: (state.main.params && state.main.params.device) || '',
		scheduling: state.store.store && state.store.store.scheduling,
		userCpf: state.user.cpf || '',
		URLParameters: (state.main.URLParameters) || '',
		addressPostalCodeRequest: (state.address && state.address.postalCodeRequest) || false,
		addressRequest: (state.address && state.address.request) || false,
		address: (state.address && state.address.address) || {},
		userCard: (state.main.userCard) || {},
		orderId: (state.main.order && state.main.order.id) || (state.main.order && state.main.order.finishData && state.main.order.finishData.vqpOrderId) || 0,
		storeLoyaltyPoints: (state.store.store && state.store.store.loyaltyPoints && state.store.store.loyaltyPoints[0]) || {},
		minForFreeShipping: state.store && state.store.store && state.store.store.minForFreeShipping,
		selectedDeliveryDate: state.main.selectedDeliveryDate || null,
	}
}

const GetConnection = connect(mapStateToProps, {
	setPaymentMethod: payment.setPaymentMethod,
	setPaymentPayback: payment.setPaymentPayback,
	addCoupon: coupon.addCoupon,
	setCoupon: coupon.setSelectedCoupon,
	setCartCoupon: cart.setCartCoupon,
	setSelectedCoupon: coupon.setSelectedCoupon,
	setAddressPostalCodeRequest: addressActions.setAddressPostalCodeRequest,
	setAddressRequest: addressActions.setAddressRequest,
	addOrder: order.addOrder,
	setLoading: loading.setLoading,
	setQuantity,
	setSelectedDeliveryDate: setSelectedDeliveryDate,
	setCart,
	setUserHistory,
	setNotification,
	setCVV,
	setProductsRemoved,
	handleGTM,
	setScheduling,
	setURLParameters
})

export const CartPix = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'cart-pix' }),
	withTheme
)(CartPixPage))
