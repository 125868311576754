import {
	MUNDIPAGG_PUBLIC_KEY
} from '../../config'

export default ({
  apiService
}) => {
  async function fetchMethods({
		data,
		auth,
		maps
	}) {
		const {
			storeId
		} = data

		const {
			mapPaymentMethodsData
		} = maps

		const url = `Cartoes/Listar/${storeId}?delivery=true`
		const result = await apiService.get(url, auth)
		const mappedResult = mapPaymentMethodsData(result.data)

		return mappedResult
	}

	async function postPaymentNewCard({
		data,
		auth,
		maps
	}) {
		const {
			cardData
		} = data

		const {
			mapAddCardRequestToApi,
			mapAddCardResponse
		} = maps

		const url = `Cartoes/Cadastrar`
		const result = await apiService.post(url, mapAddCardRequestToApi(cardData), auth)
		const mappedResult = mapAddCardResponse(result.data)

		return mappedResult
	}

	async function fetchMundipaggAccessToken({
		auth,
		maps
	}) {
		const {
			mapMundipaggAccessTokenData
		} = maps

		const url = `Cartoes/ObterAccessToken`
		const result = await apiService.post(url, {}, auth)
		const mappedResult = mapMundipaggAccessTokenData(result.data)

		return mappedResult
	}

	async function postNewCardMundipagg({
		data,
		maps
	}) {
		const {
			card,
			mundipaggUserId,
			accessToken
		} = data

		const {
			mapCardToMundipaggApiRequest
		} = maps

		const url = `https://api.mundipagg.com/core/v1/customers/${mundipaggUserId}/cards?appId=${MUNDIPAGG_PUBLIC_KEY}`
		const result = await apiService.post(url, mapCardToMundipaggApiRequest(card), {
			headers: {
				'Authorization': `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			}
		})
		const mappedResult = result.data
	
		return mappedResult
	}

	async function deleteMethod({
		auth,
		data,
		maps
	}) {
		try {
			const {
				card
			} = data

			const {
				mapDeleteCardRequestToApi,
				mapDeleteCardResponse
			} = maps

			const url = `Cartoes/Excluir`
			const result = await apiService.post(url, mapDeleteCardRequestToApi(card), auth)
			const mappedResult = mapDeleteCardResponse(result.data)
			
			return mappedResult
		} catch (e) {
			console.log(e)
		}
	}

	async function fetchPixPaymentStatus({
		data,
		maps,
		auth
	}) {
		const {
			orderId
		} = data

		const {
			mapPixPaymentStatusData
		} = maps

		const url = `pix/ObterStatusPagamento?comandaId=${orderId}`
		const result = await apiService.get(url, auth)
		const mappedResult = mapPixPaymentStatusData(result.data)

		return mappedResult
	}

	async function fetchPixPaymentData({
		data,
		maps,
		auth
	}) {
		const {
			orderId
		} = data

		const {
			mapPixPaymentDataData
		} = maps

		const url = `pedidos/ObterDadosPix?comandaId=${orderId}`
		const result = await apiService.get(url, auth)
		const mappedResult = mapPixPaymentDataData(result.data)

		return mappedResult
	}

  return {
    fetchMethods,
		fetchMundipaggAccessToken,
		postPaymentNewCard,
		deleteMethod,
		postNewCardMundipagg,
		fetchPixPaymentStatus,
		fetchPixPaymentData
  }
}
