import React, { useState } from 'react'

import {
  CustomLoading,
  Div
} from '../../components'

import {
  Wrapper,
  H3,
  Button,
  ButtonLabel
} from './styles'

import {
  SelectedItems,
  Coupon,
	PaymentMethodSelection
} from './'
import { Dialog, Slide } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

export function Details(props) {
	const [paymentMethodSelectionOpened, setPaymentMethodSelectionOpened] = useState(false)

  const {
    selectedPaymentMethod,
    address,
    coupon,
    setCoupon,
    handleAddCoupon,
    handleRemoveCoupon,
    handleViewCoupons,
    handleHasCpf,
    handleFinish,
    hasCpf,
    cpf,
    loading,
    cartCoupon,
    theme,
    modalityId,
    storeAddress,
    scheduling,
    setDeliveryDateSelectionOpened,
    URLParameters,
    error,
    success,
    couponField,
		above,
    selectedDeliveryDate
  } = props

  return (
    <Wrapper>
      <H3>Confira os detalhes do seu pedido!</H3>

      <Div>
        <SelectedItems
          cpf={cpf}
          hasCpf={hasCpf}
          handleHasCpf={handleHasCpf}
          selectedPaymentMethod={selectedPaymentMethod}
          address={address}
          theme={theme}
          modalityId={modalityId}
          storeAddress={storeAddress}
          scheduling={scheduling}
          URLParameters={URLParameters}
					changeSelectedPaymentMethod={() => setPaymentMethodSelectionOpened(true)}
          changeSelectedDeliveryDate={() => setDeliveryDateSelectionOpened(true)}
					above={above}
          selectedDeliveryDate={selectedDeliveryDate}
        />

			<Dialog
				onClose={() => setPaymentMethodSelectionOpened(false)}
				aria-labelledby='payment-method-selection'
				open={paymentMethodSelectionOpened}
				TransitionComponent={Transition}
				maxWidth={'xs'}
				PaperProps={{
					style: {
						width: '25rem',
						borderRadius: '.75rem'
					}
				}}
				fullWidth={false}
			>
				<PaymentMethodSelection close={() => setPaymentMethodSelectionOpened(false)}/>
			</Dialog>

        <Coupon
          cartCoupon={cartCoupon}
          coupon={coupon}
          setCoupon={setCoupon}
          handleAddCoupon={handleAddCoupon}
          handleRemoveCoupon={handleRemoveCoupon}
          handleViewCoupons={handleViewCoupons}
          error={error}
          success={success}
          couponField={couponField}
        />
      </Div>

      <Button id='finish-purchase-button' onClick={() => {
        handleFinish()
      }}>
        <ButtonLabel >{loading.includes('payment') ?
          <CustomLoading
            color={'#fff'}
            type={'spin'}
            id='default-loading'
            height={30}
            width={30} /> :
          'Finalizar Compra'}</ButtonLabel>
      </Button>
    </Wrapper>
  )
}
