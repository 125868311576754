import styled from "styled-components";

export const Wrapper = styled.div`
	border: ${(props) =>
			(props.theme &&
				props.theme.main &&
				props.theme.main.general &&
				props.theme.main.general.textNinethColor) ||
			"#000"}
		0.125rem solid;
	color: ${(props) =>
		(props.theme &&
			props.theme.main &&
			props.theme.main.general &&
			props.theme.main.general.textHighlightColor) ||
		"#000"};
	border-radius: 0.5rem;
	padding: 1rem;
	min-height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
	font-weight: bold;
	position: relative;
`;

export const Paragraph = styled.p`
	max-width: 100%;
	word-break: break-all;
	margin-right: 1.5rem;
`;

export const CopyButton = styled.button`
	background: none;
	border: 0 none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	line-height: 1rem;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translate(0, -50%);
	cursor: pointer;

	> {
		pointer-events: none;
	}
`;
