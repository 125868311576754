import React, {
	useEffect
} from 'react'
import {
	connect,
	useDispatch,
	useStore
} from 'react-redux'
import PropTypes from 'prop-types'

import history from '../../../history'

import {
	setUserHistory,
	setScheduling,
	setNotification
} from '../../../redux/actions/main'

import {
	payment,
	order
} from '../../../state'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
	useDeviceLayout
} from '../../hooks'

import {
	Button
} from '../../components'

import {
	Tabs
} from './Tabs'

import {
  Wrapper,
  H3
} from './styles'

export const Context = React.createContext({})

export function PixComponent() {
	const store = useStore()
	const dispatch = useDispatch()

	const pixType = (store.getState().payment && store.getState().payment.pixType) || 0
	const URLParameters = (store.getState().main && store.getState().main.URLParameters) || ''

	const isMobile = useDeviceLayout({
    isMobile: true
  })

	useEffect(() => {
    return () => {}
	}, [])

	function handleType(index) {
		payment.setPixType(index)
	}

	async function handleOk() {
		dispatch(payment.setPixRequest(false))

		if (!isMobile) {
			await dispatch(order.addOrder([{
				name: 'order',
				type: 'error',
				callback: () => {
					dispatch(payment.setPaymentMethod(null))
					dispatch(payment.setPaymentPayback(0))
					dispatch(setNotification({
						type: 'warning',
						message: 'Ocorreu um erro no pagamento.'
					}))
					dispatch(setScheduling(null))
				}
			}, {
				name: 'order',
				type: 'success',
				callback: () => {
					/*dispatch(payment.setPaymentMethod(null))
					dispatch(payment.setPaymentPayback(0)) */
					dispatch(setScheduling(null))

					history.push(`/order-confirmation${URLParameters}`)
				}
			}, {
				name: 'address',
				type: 'error',
				callback: () => {
					dispatch(setUserHistory({
						next: 'cart',
						back: 'cart'
					}))

					dispatch(setNotification({
						type: 'warning',
						message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
					}))

					history.push(`/new-address${URLParameters}`)
				}
			}, {
				name: 'address',
				type: 'success',
				callback: () => {
					console.warn('Address created')
				}
			}]))
		} else {
			history.push(`/cart${URLParameters}`)
		}
	}

	return <Wrapper id='payment-method-pix-how-to'>
		<H3>Como pagar com PIX:</H3>

		<Tabs tab={pixType} handleType={handleType} />

		<Button onClick={ () => {
			handleOk()
		}}>Ok</Button>
	</Wrapper>
}

PixComponent.propTypes = {
	setPixRequest: PropTypes.func,
	setPixType: PropTypes.func,
	addOrder: PropTypes.func,
	setPaymentMethod: PropTypes.func,
	setNotification: PropTypes.func
}

const mapStateToProps = (state) => {
  return state
}

const GetConnection = connect(mapStateToProps, {})

export const Pix = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'payment' })
)(PixComponent))
