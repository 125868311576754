export default ({
  paymentRepository,
  maps,
  auth
}) => {
  return async (payload) => {
    const {
      onSuccess,
      onError,
      data
    } = payload

    try {
      const deletedPaymentMethod = await paymentRepository.deleteMethod({
        data,
        maps,
        auth: auth()
      })

      onSuccess({ deletedPaymentMethod })
    } catch(e) {
      console.log(e)

      onError()
    }
  }
}
