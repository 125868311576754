import React, {
	useEffect, useState
} from 'react'
import {
	connect,
} from 'react-redux'

import {
	pipe,
	GetContent
} from '../../../../domain/helpers'

import {
	Button
} from '../../../components'

import {
	Tabs
} from './Tabs'

import {
	Wrapper,
	H3
} from './styles'

export const Context = React.createContext({})

export function InfoComponent(props) {
	const { setOpenDialogPix } = props
	const [pixType, setPixType] = useState(0)

	useEffect(() => {
		return () => { }
	}, [])

	function handleType(index) {
		setPixType(index)
	}

	async function handleOk() {
		setOpenDialogPix(false)
	}

	return <Wrapper id='payment-method-pix-how-to'>
		<H3>Como pagar com PIX:</H3>

		<Tabs tab={pixType} handleType={handleType} />

		<Button onClick={() => {
			handleOk()
		}}>Ok</Button>
	</Wrapper>
}

InfoComponent.propTypes = {}

const mapStateToProps = (state) => {
	return {
		loading: (state.store.store && state.loading.loading) || [],
	}
}

const GetConnection = connect(mapStateToProps, {})

export const Info = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: '' })
)(InfoComponent))
