import React, {
	useEffect
} from 'react'
import PropTypes from 'prop-types'

import {
  Wrapper,
	TabWrapper,
	TabButton,
	ActionsWrapper,
	ContentWrapper,
	TabSubtitle,
	TabText
} from './styles'

export function Tabs(props) {
	const {
		tab,
		handleType
	} = props

	useEffect(() => {
    return () => {}
	}, [])

	function handleTab(index) {
		handleType(index)
	}

	return <Wrapper id='payment-method-pix-how-to'>
		<ActionsWrapper>
			<TabButton className={`${tab === 0 ? 'active' : ''}`} onClick={() => {
				handleTab(0)
			}}>
				Copia e Cola
			</TabButton>
			<TabButton className={`${tab === 1 ? 'active' : ''}`} onClick={() => {
				handleTab(1)
			}}>
				QR Code
			</TabButton>
		</ActionsWrapper>

		<ContentWrapper>
			{tab === 0 && <TabWrapper id='payment-method-pix-copy-paste'>
				<TabSubtitle>1º Passo</TabSubtitle>
				<TabText>Copie o código que foi gerado.</TabText>

				<TabSubtitle>2º Passo</TabSubtitle>
				<TabText>Abra um aplicativo em que você tenha o Pix habilitado e use a opção Pix Cópia e Cola</TabText>

				<TabSubtitle>3º Passo</TabSubtitle>
				<TabText>Cole o código, confirme o valor e faça o pagamento. Ele será confirmado na hora :)</TabText>
			</TabWrapper>}

			{tab === 1 && <TabWrapper id='payment-method-pix-qr-code'>
				<TabSubtitle>1º Passo</TabSubtitle>
				<TabText>Abra um aplicativo em que você tenha o Pix habilitado e escolha a opção Pagar, em seguida Ler QR Code</TabText>

				<TabSubtitle>2º Passo</TabSubtitle>
				<TabText>Aponte a câmera do celular para o QR Code que está na tela de pagamento</TabText>

				<TabSubtitle>3º Passo</TabSubtitle>
				<TabText>Confirme o valor e faça o pagamento, ele será confirmado na hora :)</TabText>
			</TabWrapper>}
		</ContentWrapper>
	</Wrapper>
}

Tabs.propTypes = {
	tab: PropTypes.number,
	handleType: PropTypes.func
}
