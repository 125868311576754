import styled from 'styled-components'

export const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;
	margin: 0 auto;
	padding: 0;
	min-width: 100%;
	margin-bottom: 1rem;
`

export const TabWrapper = styled.div``

export const TabButton = styled.button``

export const ActionsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	min-width: 100%;
	margin-bottom: 1rem;

	> button {
		background: #fff;
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
		border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000' } .0625rem solid;
		color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
		min-height: 3rem;
		font-size: 1rem;
		box-sizing: border-box;
		font-weight: bold;

		&.active {
			border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .2rem solid;
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
		}
	}
`

export const ContentWrapper = styled.div``

export const TabSubtitle = styled.h4`
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
	margin: 0;
	padding: 0;
`

export const TabText = styled.p`
	font-size: 1rem;
	padding: 0;
	margin: 0;
	margin-bottom: 1rem;
`
