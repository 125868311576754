import React from 'react'
import PropTypes from 'prop-types'

import {
  getCardBrandImageById
} from '../../../../infra/utils'

import { ReactComponent as DeleteIcon } from '../../../assets/icons/icon_delete.svg'
// import { ReactComponent as NoCards } from '../../../assets/seara/icone_cartao.svg'

import {
	Button,
  CustomCheckbox
} from '../../../components'

import {
	Wrapper,
	H3,
	List,
	ListItem,
	ListItemImage,
	ListItemContent,
  ListItemActions,
	ActionContainer,
} from './styles'

export function PaymentMethodsGroup(props) {
	const {
		name,
		data,
		setSelectRemoveCard,
		handleOpenModal,
		handleCard,
		handleNewItem,
		addNewItem,
		isOffline,
		removeItem,
		selectItem
	} = props

	return <Wrapper>
		<H3>{name}</H3>

		<List>
			{data && data.map((item, index) => {
				const itemName = item.data
				const itemId = item.id
				const key = `payment-methods-group-item-${index}-${itemId}`

				return <ListItem key={key} data={item}>
					<ListItemContent>
						<ListItemImage
							src={item.image || ''}
							alt={`Bandeira do cartao: ${getCardBrandImageById(item.brand) || ''}`}
						/>
						{itemName}
					</ListItemContent>

					<ListItemActions>
						{selectItem && <CustomCheckbox
							onClick={() => handleCard({ selectCard: item, isOffline })}
							type='radio'
							name='field'
						></CustomCheckbox>}
						{removeItem &&<DeleteIcon onClick={() => {
							setSelectRemoveCard(item)
							handleOpenModal()
						}} />}
					</ListItemActions>
				</ListItem>
			})}
		</List>

		{addNewItem && <>
			<ActionContainer>
				<Button onClick={() => {
					handleNewItem(true)
				}}>
					Adicionar Cartão
				</Button>
			</ActionContainer>
		</>}
	</Wrapper>
}

PaymentMethodsGroup.propTypes = {
	name: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.shape({})),
	setSelectRemoveCard: PropTypes.func,
	handleOpenModal: PropTypes.func,
	handleAddUserCard: PropTypes.func,
	handleCard: PropTypes.func,
	addNewItem: PropTypes.bool,
	handleNewItem: PropTypes.func,
	isOffline: PropTypes.bool,
	removeItem: PropTypes.bool,
	selectItem: PropTypes.bool
}
