export default ({ apiService }) => {
	const strategy = {
		orders: async ({ data, auth, dispatch, actions, maps }) => {
			const { setLoading } = actions;

			const { loading } = data;

			const { mapOrdersList } = maps;

			if (!auth) {
				return false;
			}

			if (loading && loading.length && loading.indexOf("orders") > -1) {
				return false;
			}

			dispatch(setLoading("orders"));

			const url = `Pedidos/ListarStatusPedidosEmAndamento`;
			const result = await apiService.get(url, auth);
			const mappedResult = mapOrdersList(result.data);

			return mappedResult;
		},
		orderHistory: async ({ data, auth, dispatch, actions, maps }) => {
			const { setLoading } = actions;

			const { loading, pagination = {} } = data;

			const { offset: paginationOffset, length: paginationLength } = pagination;

			const { mapOrderHistoryList } = maps;

			if (loading && loading.length && loading.indexOf("order-history") > -1) {
				return false;
			}

			dispatch(setLoading("order-history"));

			const url = `Pedidos/Listar?ultimoRegistro=${paginationOffset}&quantidade=${paginationLength}`;
			const result = await apiService.get(url, auth);
			const mappedResult = mapOrderHistoryList(result.data);

			return mappedResult;
		},
		order: async ({ data, auth, dispatch, actions, maps }) => {
			const { setLoading } = actions;

			const { loading, orderId, partnerOrderId } = data;

			const { mapOrderData } = maps;

			if (loading && loading.length && loading.indexOf("order") > -1) {
				return false;
			}

			dispatch(setLoading("order"));

			const url = `Pedidos/ObterComprovante?${
				partnerOrderId
					? `parceiroPedidoId=${partnerOrderId}`
					: `comandaId=${orderId}`
			}&a=2`;
			const result = await apiService.get(url, auth);
			const mappedResult = mapOrderData(result.data);

			return mappedResult;
		},
		addOrder: async ({ data, auth, dispatch, actions, maps }) => {
			const { setLoading } = actions;

			const { loading } = data;

			const { mapOrderCreateData, mapOrderCreateRequest } = maps;

			if (loading && loading.length && loading.indexOf("add-order") > -1) {
				return false;
			}

			dispatch(setLoading("add-order"));

			const url = `Pedidos/Adicionar`;
			const result = await apiService.post(
				url,
				mapOrderCreateRequest(data),
				auth
			);
			const mappedResult = mapOrderCreateData(result.data);

			return mappedResult;
		},
	};

	async function addOrderAnalysis({ data, auth, dispatch, actions, maps }) {
		const { setLoading } = actions;

		const { loading } = data;

		const { mapOrderAnalysisData, mapOrderAnalysisRequest } = maps;

		if (loading && loading.length && loading.indexOf("analysis-order") > -1) {
			return false;
		}

		dispatch(setLoading("analysis-order"));

		const url = `Pedidos/Analisar`;
		const result = await apiService.post(
			url,
			mapOrderAnalysisRequest(data),
			auth
		);
		const mappedResult = mapOrderAnalysisData(result.data);

		return mappedResult;
	}

	return {
		fetchOrder: async (payload) => {
			return await strategy["order"](payload);
		},

		fetchOrders: async (payload) => {
			return await strategy["orders"](payload);
		},

		fetchOrderHistory: async (payload) => {
			return await strategy["orderHistory"](payload);
		},

		addOrder: async (payload) => {
			return await strategy["addOrder"](payload);
		},

		addOrderAnalysis,
	};
};
