import styled from 'styled-components'

export const Wrapper = styled.div`
	background: #fff;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;
	margin: 0 auto;
	border-radius: 1rem;
	padding: 1.25rem;
	min-width: 100%;

	> button {
		min-width: 100%;
		max-height: 3rem;
		min-height: 3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
`

export const H3 = styled.h3`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1.25rem;
  padding: 0;
  margin: 0;
	margin-bottom: 1rem;
	flex: 1;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 100%;
`
