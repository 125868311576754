import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { copyTextToClipboard } from '../../../domain/helpers'

import {
	Button
} from '../../components'

import {
	CopyPasteCode
} from '.'

import {
	Wrapper,
	H3,
	Paragraph,
	NoBorderButton,
	Image
} from './styles'
import { Skeleton } from '../../skeleton'

export function Pix(props) {
	const {
		qrCodeImage,
		copyPasteCode,
		limitTime,
		setOpenDialogPix
	} = props

	const [localLoading, setLocalLoading] = useState(true)
	const minutesTime = limitTime > 0 ? Math.floor(limitTime / 60) : "-";

	useEffect(() => {
		setTimeout(() => { setLocalLoading(false) }, 5000)
	}, []);

	return (
		<Wrapper>
			{localLoading ? <Skeleton module='pix' /> : <>
				<H3>Aguardando Pagamento PIX</H3>

				<Paragraph>
					Abra um aplicativo em que você tenha o Pix habilitado e escolha a opção Pagar, em seguida <strong>Ler QR Code</strong>
				</Paragraph>

				<Image src={qrCodeImage} />

				<Paragraph>
					Se preferir, copie o código abaixo e utilize o Pix Copia e Cola no aplicativo que você vai fazer o pagamento:
				</Paragraph>

				<CopyPasteCode text={copyPasteCode} />

				<NoBorderButton onClick={() => setOpenDialogPix(true)}>Como funciona <span>?</span></NoBorderButton>

				<Paragraph>
					Realize o pagamento em até {minutesTime ? `${minutesTime} minutos` : `${minutesTime}`}. Após esse tempo, seu pedido será cancelado
				</Paragraph>

				<Button className="copy-pix" onClick={() => {
					copyTextToClipboard(copyPasteCode)
				}}>Copiar Código PIX</Button>
			</>}
		</Wrapper>
	)
}

Pix.propTypes = {
	qrCodeImage: PropTypes.string,
	copyPasteCode: PropTypes.string
}
