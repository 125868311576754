import { actionTypes } from '.'

const localStorageKey = 'payment'

const initialState = {
	pixRequest: false,
	pixType: 0
}

const reducer = (state = initialState, action) => {
  const localStorageData = JSON.parse(localStorage.getItem(localStorageKey)) || {}
  const loadedState = {
    ...state,
    ...localStorageData
  }
  const newState = {
    value: loadedState
  }

  switch (action.type) {
		case actionTypes.SET_SELECTED_PAYMENT_METHOD:
      Object.assign(newState, {
        value: {
          ...newState.value,
          selectedPaymentMethod: action.payload
        }
      })

      break
		
		case actionTypes.SET_PAYMENT_METHODS:
			Object.assign(newState, {
				value: action.payload
			})

			break

		case actionTypes.SET_PAYMENT_PAYBACK:
			Object.assign(newState, {
				value: {
					...newState.value,
          payback: action.payload
				}
			})

			break

		case actionTypes.SET_PIX_PAYMENT_DATA:
			Object.assign(newState, {
				value: {
					...newState.value,
					pixPaymentData: action.payload
				}
			})

			break

		case actionTypes.SET_PIX_PAYMENT_STATUS:
			Object.assign(newState, {
				value: {
					...newState.value,
					pixPaymentStatus: action.payload
				}
			})

			break

		case actionTypes.SET_PIX_REQUEST:
			Object.assign(newState, {
				value: {
					...newState.value,
          pixRequest: action.payload
				}
			})

			break

		case actionTypes.SET_PIX_TYPE:
			Object.assign(newState, {
				value: {
					...newState.value,
					pixType: action.payload
				}
			})

			break

		case actionTypes.SET_MUNDIPAGG_DATA:
			Object.assign(newState, {
				value: {
					...newState.value,
					mundipaggData: action.payload
				}
			})

			break

		case actionTypes.SET_MUNDIPAGG_CARD:
			Object.assign(newState, {
				value: {
					...newState.value,
					mundipaggCard: action.payload
				}
			})

			break

		case actionTypes.SET_SELECTED_PAYMENT_TYPE:
			Object.assign(newState, {
				value: {
					...newState.value,
					selectedPaymentType: action.payload
				}
			})

			break

		case actionTypes.SET_CARD:
      Object.assign(newState, {
        value: {
          ...newState.value,
          card: action.payload
        }
      })

      break

		case actionTypes.SET_CARD_NAME:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						name: action.payload
					}
				}
			})

			break

		case actionTypes.SET_CARD_NUMBER:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						number: action.payload
					}
				}
			})

			break

		case actionTypes.SET_CARD_DATE:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						date: action.payload
					}
				}
			})

			break

		case actionTypes.SET_CARD_BIRTH:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						birth: action.payload
					}
				}
			})

			break


		case actionTypes.SET_CARD_CPF:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						CPF: action.payload
					}
				}
			})

			break

		case actionTypes.SET_CARD_BRAND_ID:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						brandId: action.payload
					}
				}
			})

			break

		case actionTypes.SET_CARD_CVV:
			Object.assign(newState, {
				value: {
					...newState.value,
					card: {
						...newState.value.card,
						CVV: action.payload
					}
				}
			})

			break

    default:
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState.value))

  return newState.value
}

export default reducer
