export default {
	SET_SELECTED_PAYMENT_METHOD: 'SET_SELECTED_PAYMENT_METHOD',
	SET_SELECTED_PAYMENT_METHODS: 'SET_SELECTED_PAYMENT_METHODS',
	SET_SELECTED_PAYMENT_TYPE: 'SET_SELECTED_PAYMENT_TYPE',
	SET_MUNDIPAGG_DATA: 'SET_MUNDIPAGG_DATA',
	SET_MUNDIPAGG_CARD: 'SET_MUNDIPAGG_CARD',
	SET_PAYMENT_NEW_METHOD: 'SET_PAYMENT_NEW_METHOD',
	SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS',
	SET_PAYMENT_PAYBACK: 'SET_PAYMENT_PAYBACK',
	SET_PIX_PAYMENT_STATUS: 'SET_PIX_PAYMENT_STATUS',
  SET_PIX_PAYMENT_DATA: 'SET_PIX_PAYMENT_DATA',
	SET_PIX_REQUEST: 'SET_PIX_REQUEST',
	SET_PIX_TYPE: 'SET_PIX_TYPE',
	SET_CARD: 'SET_CARD',
	SET_CARD_NUMBER: 'SET_CARD_NUMBER',
	SET_CARD_CVV: 'SET_CARD_CVV',
	SET_CARD_NAME: 'SET_CARD_NAME',
	SET_CARD_DATE: 'SET_CARD_DATE',
	SET_CARD_BRAND_ID: 'SET_CARD_BRAND_ID',
	SET_CARD_BRAND_NAME: 'SET_CARD_BRAND_NAME',
	SET_CARD_BIRTH: 'SET_CARD_BIRTH',
	SET_CARD_CPF: 'SET_CARD_CPF'
}
