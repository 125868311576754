import styled from 'styled-components'

import { fadeIn } from '../../components'

export const Wrapper = styled.div`
  background: #fff;
  flex: 1;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  ${props => props.maxHeight ?
    `max-height: ${ props.maxHeight }px;
    min-height: ${ props.maxHeight }px;
    overflow: hidden;` :
    ``}

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > div {
    display: flex;
    min-height: 100%;

    > main {
      background: #fff;
      min-height: 100%;
      max-height: 100%;
      box-sizing: border-box;
      position: relative;

      > img {
        height: auto;
        max-height: unset;
        max-width: 100%;
        min-height: auto;
        min-width: 100%;
        width: 100%;
        display: none;

        @media (min-width: 60rem) {
          display: unset;
        }
      }
    }
  }

  @media (min-width: 60rem) {
    > div {
      > main {
        min-height: calc(100% - 5.625rem);
        max-height: calc(100% - 5.625rem);
        margin: 0px auto;
        box-sizing: unset;

				> div {
					&.row {
						margin: 0;
						padding: 1rem 2rem;
					}
				}	
      }
    }
  }
`

export const ContentWrapper = styled.div`
  flex: 1;
  min-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  ${fadeIn}
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
  font-size: 1rem;
`

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  color: #FFF;
  font-size: 1rem;
  position: absolute;
  cursor: pointer;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 1;

  @media (min-width: 40rem) {
    display: none;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}
  flex: 1;
`

export const SubHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem 0;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;

  #store-search {
    min-width: auto;
    flex: 1;
  }

  @media (min-width: 60rem) {
    padding: 2rem 2rem 0;

    #store-search {
      flex: 0 0 calc(60% - .5rem);
    }
  }
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: none;
  text-align: center;
  border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' } .063rem solid;
  padding-bottom: 1.25rem;
	box-sizing: border-box;

  @media (min-width: 60rem) {
    text-align: left;
    font-size: 1.25rem;
    text-transform: uppercase;
    padding: 1rem 0rem 1rem;
		margin: 2rem 2rem 1rem 2rem;
    min-width: calc(100% - 4rem);
  }
`
export const WhattsAppImg = styled.div`
  width: 4rem;
  height: 4rem;
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
  z-index: 1;

  &.ativo{
    bottom: 7rem;
  }
  
  img, svg {
    width: 4rem;
    height: 4rem;
    display: block;
  }
`
