import styled from 'styled-components'

export const Wrapper = styled.div`
	font-family: ${props => props.theme.newFontFamily};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;
	margin: 0 auto;
	min-width: 100%;
	max-width: 100%;
  padding: 0;
	max-height: calc(100% - (8rem + 4rem));
	min-height: calc(100% - (8rem + 4rem));
  flex: 1;
	overflow: hidden;
	overflow-y: auto;
`

export const ListWrapper = styled.ul`
	list-style: inside none;
	padding: 0;
	margin: 0;
	display: flex;
	flex: 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-bottom: 2.5rem;
	width: 100%;
`

export const ListItemWrapper = styled.li`
	flex: 1;
	padding: 0 1.5rem;
	margin: 0;
	min-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 1.875rem;
  	box-sizing: border-box;
		max-width: 100%;

	&:last-child {
		margin-bottom: 0;
	}

	@media (min-width: 50rem) {
    padding: .4rem;
		justify-content: space-between;
	}
`

export const MessageWrapper = styled.div``

export const H1 = styled.h1`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#333'};
  font-size: 1.75rem;
  line-height: 2rem;
  padding: 0 1.25rem;
  margin: 0;
  margin-bottom: 3.75rem;

  > span {
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#333' };
  }
`

export const H2 = styled.h2`
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
	font-size: 1rem;
	padding: 0 1.5rem;
	font-weight: 700;

	@media (min-width: 50rem) {
		margin: 0 0 1rem 0;
    padding: 0;
		width: 100%;
	}
`

export const ProductWrapper = styled.div`
	flex: 1;
	margin: 0;
	min-width: 70%;
	max-width: 70%;
	
	>div {
		&.additionals{
			padding: 20px 0;

			>div{
				&.additionals-double{
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding: 3px 0;
				}
		
				>p{
					&.additional{
						font-size: .875rem;
						color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
					}
			
					&.additional-value{
						font-size: .875rem;
						color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
					}

					&.additional-free{
						font-size: .875rem;
						color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textEighthColor) || '#000' };
					}
				}
			}
		}
	}

	> p {
		&.name {
			font-weight: 600;
			font-size: 1rem;
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
		}

		&.description {
			margin-bottom: .938rem;
			font-size: .875rem;
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
		}

		&.value {
			margin-top: .2rem;
			font-size: 1rem;
			font-weight: bold;
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
		}

		&.acompanhamentos{
			font-size: .75rem;
		}
	}
`

export const ActionsWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	min-width: 30%;
	max-width: 10rem;
	
`

export const Span = styled.span``

export const SummaryWrapper = styled.div`
	padding: 1rem 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 100%;
	min-width: 100%;
  	box-sizing: border-box;
	flex: none;
	border-bottom: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#EEE'};
	border-top: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#EEE'};

	> p {
		line-height: 1rem;
		font-size: 1rem;

		&.subtotal {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
			font-size: 1rem;
			margin-bottom: .625rem;
		}

    	&.discount {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
			font-size: 1rem;
			margin-bottom: .625rem;
      	font-weight: bold;

			> span {
				&.value {
				color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
				}
			}
		}

		&.fee {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
			font-size: 1rem;
			margin-bottom: 1.25rem;
		}

		&.total {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
			font-weight: 700;
			font-size: 1.25rem;
			line-height: 1.125rem;

			> span {
				font-size: 1.25rem;
			}
		}

		> span {
			flex: 1;

			&.value {
				align-self: flex-end;
				flex: none;
				color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
				font-size: 1rem;

				&.total {
					color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
					font-weight: 700;
					font-size: 1.25rem;
				}
			}
		}
	}

	@media (min-width: 50rem) {
		width: 100%;
		padding: 1.5rem 0;
		margin-bottom: 1rem;
		max-width: none;
	}
`

export const Paragraph = styled.p`
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#333'};
	padding: 0;
	margin: 0;
	font-size: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1;
	min-width: 100%;
`

export const Button = styled.button`
	background: none;
	padding: 0;
	margin: 0;
`

export const QuantityControlWrapper = styled.div`
	border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.greyMedium) || '#333'} .125rem solid;
	border-radius: 1.5rem;
	max-height: 3rem;
	min-height: 3rem;
	align-self: flex-end;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	width: 5.25rem;
	height: 2.25rem;
	padding: .5rem;
	min-height: auto;

	> button {
		flex: 1;
		color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
		font-size: .875rem;
		text-align: start;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		&:last-of-type {
			text-align: end;
		}

		> span {
      &.icon {
        > svg {
        padding-top: .5rem;
        
        path {
          fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
        }
        }
      }
		}
	}

	> span {
		flex: 1;
		margin: 0 auto;
		color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
		font-size: 1rem;
		line-height: 1.125rem;
		height: 1.125rem;
		font-weight: bold;
		text-align: center;
	}

	@media (min-width: 50rem) {
		width: 5.25rem;
		height: 2.25rem;
		padding: .25rem .5rem;
		border: .15rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey01) || '#DDD' };
		min-height: auto;
	}
`

export const EmptyBasketContainer = styled.div`
	border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
	padding: 1rem;
  box-sizing: border-box;

	svg {
		width: 9.5rem;

		path {
			fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
			stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
		}
	}

	h4 {
		margin: 1rem 0 0;
		font-weight: 700;
		text-align: center;
	}

	p {
		margin: .25rem 0 0 0;
    	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
		text-align: center;
	}
`

export const GreetingsTitle = styled.h2`
	font-size: 1.5rem;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
	font-weight: 700;
	margin: 1.5rem 1.5rem 2rem 1.5rem;

	span {
		color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
	}
`

export const SimpleQuantity = styled.span`
	border: 2px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
`
export const Space = styled.div`
	height: 5rem;
	width: 100%;
`
